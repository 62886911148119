<template>
  <van-tabbar v-model="active">
    <van-tabbar-item>
      <template #icon>
        <router-link to="/" :class="active === 0 ? 'tabbar aactive' : 'tabbar '"
          ><van-icon name="wap-home-o" />
          <p class="tabbar-title">首页</p></router-link
        ></template
      ></van-tabbar-item
    >
    <van-tabbar-item>
      <template #icon>
        <router-link
          to="/game"
          :class="active === 1 ? 'tabbar aactive' : 'tabbar '"
          ><van-icon name="cluster-o" />
          <p class="tabbar-title">生产</p></router-link
        ></template
      ></van-tabbar-item
    >
    <van-tabbar-item>
      <template #icon>
        <router-link
          to="/shop"
          :class="active === 2 ? 'tabbar aactive' : 'tabbar '"
          ><van-icon name="shop-o" />
          <p class="tabbar-title">市场</p></router-link
        ></template
      ></van-tabbar-item
    >
    <!-- <van-tabbar-item>
      <template #icon>
        <router-link
          to="/simulation"
          :class="active === 3 ? 'tabbar aactive' : 'tabbar '"
          ><van-icon name="shop-o" />
          <p class="tabbar-title">模拟器</p></router-link
        ></template
      ></van-tabbar-item
    > -->
    <van-tabbar-item>
      <template #icon>
        <router-link
          to="/other"
          :class="active === 3 ? 'tabbar aactive' : 'tabbar '"
          ><van-icon name="setting-o" />
          <p class="tabbar-title">其他</p></router-link
        ></template
      ></van-tabbar-item
    >
  </van-tabbar>
</template>

<script>
export default {
  name: "Tabbar",
  props: {},
  data() {
    return {};
  },
  computed: {
    active() {
      let path = [
        "/",
        "/game",
        "/shop",
        // , "simulation"
        "/other",
      ];
      return path.indexOf(this.$route.path);
    },
  },
};
</script>

<style scoped>
a {
  color: var(--van-tabbar-item-text-color);
}
.aactive {
  color: var(--van-tabbar-item-active-color);
}
.tabbar-title {
  margin-top: 4px;
  font-size: 12px;
}
.tabbar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
