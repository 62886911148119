<template>
  <div v-if="coins" class="nft-coins-container" @click="show = !show">
    <div v-for="coin of coins" :key="coin.balance">
      <van-tag
        v-if="parseFloat(coin.balance) > 0"
        class="nft-coin-tag"
        plain
        type="primary"
        ><img class="nft-worker-asset" :src="getAssetPng(coin.balance)" />
        <p class="nft-get-count">
          {{ parseFloat(coin.balance) }}
        </p></van-tag
      >
    </div>
    <p class="show-tip" v-show="orders && orders.length > 0">
      点击{{ !show ? "显示" : "隐藏" }}系统可回收订单
    </p>
  </div>
  <div
    class="home-nft-orders-container"
    v-if="show && orders && orders.length > 0"
  >
    <van-tag mark type="primary">{{ account }} 的可系统回收订单</van-tag>
    <div
      class="data-nft-orders-wrap"
      v-for="targetOrder of orders"
      :key="`${targetOrder.id}`"
    >
      <div class="nft-top">
        <van-tag
          plain
          class="nft-worker-item"
          type="primary"
          v-for="asset of targetOrder.require_asset"
          :key="`${asset}`"
          ><img class="nft-worker-asset" :src="getAssetPng(asset)" />
          <p class="nft-get-count">
            {{ "" + parseFloat(asset).toFixed(0) }}
          </p></van-tag
        ><van-icon name="/right-arrow.png" class="nft-worker-arrow" />
        <van-tag plain class="nft-worker-item" type="primary"
          ><img class="nft-worker-asset" :src="getAssetPng(targetOrder.pay)" />
          <p class="nft-get-count">
            {{ "" + parseFloat(targetOrder.pay).toFixed(2) }}
          </p>
        </van-tag>
        <!-- TODO 订单附加  -->
        <van-tag plain class="nft-worker-item" type="primary">
          <img
            class="nft-worker-asset"
            src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/AMTHYST.png"
            data-v-bc1abe54=""
          />
          <p class="nft-get-count">
            1
          </p>
        </van-tag>
        <template v-if="accountName !== '' && accountName === account">
          <van-button
            v-if="waitOrders.indexOf(targetOrder.id) === -1"
            style="margin-left: 6px;"
            size="mini"
            type="danger"
            @click="recoveryOrder(targetOrder)"
            >回收</van-button
          >
          <p v-else>冷却中</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import scatter from "@/utils/scatter";
import { Toast } from "vant";
export default {
  emits: ["reload"],
  name: "HomeNFTCoins",
  props: {
    coins: null,
    account: null,
    orders: null,
    workers: null,
    orderlogs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
    }),
    getTaskPng() {
      if (this.nft && this.nft.task) {
        for (let crop of this.farmCrops) {
          if (crop.key === this.nft.task.farm) {
            return crop.url;
          }
        }
      }
      return "";
    },
    waitOrders() {
      var result = [];
      for (let orderlog of this.orderlogs) {
        let next = moment(orderlog.next_time).add("8", "h");
        let t = next.subtract(moment()).unix() / 60;
        if (t > 0) {
          result.push(orderlog.id);
        }
      }
      return result;
    },
  },
  methods: {
    moment,
    getAssetPng(asset) {
      let a = asset.split(" ")[1];
      for (let crop of this.farmCrops) {
        if (crop.key === a) {
          return crop.url;
        }
      }
      return "";
    },
    recoveryOrder(order) {
      let that = this;
      scatter.recoveryOrder(order, (e) => {
        if (scatter.hcb(e)) {
          Toast.success("回收成功");
          that.$emit("reload");
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.nft-coins-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  img {
    width: 30px;
  }
  .nft-coin-tag {
    margin-right: 10px;
  }
  p {
    font-weight: bold;
  }
  .show-tip {
    font-size: 13px;
    color: #696969;
  }
}

.home-nft-orders-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  p {
    font-size: 12px;
  }
  .top-profit-tip {
    font-size: 11px;
    color: #888888;
  }
  .data-nft-orders-wrap {
    width: 100%;
  }
  .nft-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 8px;
    .nft-img {
      width: 30px;
      margin-right: 6px;
    }
    .nft-level {
      margin-right: 6px;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
    p {
      font-size: 13px;
    }
    .nft-get-count {
      font-weight: bold;
    }
    img {
      margin: 0 2px;
      width: 30px;
    }
    .nft-workers-tip {
      font-size: 12px;
      margin-bottom: 4px;
    }
    .nft-worker-arrow {
      margin: 0 4px;
    }
    .nft-worker-item {
      margin-right: 4px;
    }
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .nft-worker-asset {
      width: 20px;
      padding: 2px 0;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
    .nft-profit {
      font-size: 11px;
      color: #888888;
    }
  }
  .nft-bottom {
    .nft-profit {
      font-size: 11px;
      color: #888888;
    }
  }
}
</style>
