<template>
  <!-- <navbar title="首页" /> -->
  <div class="home">
    <van-sticky>
      <van-cell-group>
        <van-field
          v-model="account"
          clearable
          label=""
          placeholder="请输入EOS账号"
          ><template #button>
            <van-button size="small" type="primary" @click="reloadData"
              >搜索</van-button
            >
          </template></van-field
        >
      </van-cell-group>
    </van-sticky>
    <van-loading
      size="24px"
      vertical
      color="#0094ff"
      v-if="loading && !coins && !nfts"
    >
      加载中...</van-loading
    >

    <van-button
      v-if="!loading && accountName !== '' && account !== accountName"
      style="margin: 10px 0 0 10px;"
      size="small"
      type="primary"
      @click="viewMyAccount"
      >查看自己的钱包账号</van-button
    >
    <van-button
      v-if="!loading && accountName !== ''"
      style="margin: 10px 0 0 10px;"
      size="small"
      type="primary"
      @click="toWish"
      >前往风车小镇官网</van-button
    >
    <div class="nfts" v-if="totalPrice" @click="clickOnShowTip">
      <van-tag mark type="primary">{{ account }} 账号总价值</van-tag>
      <div class="nfts-total-price">
        <img
          class="nft-worker-asset"
          src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
        />
        <p>
          <span>{{ totalPrice.price.toFixed(4) }} </span>
        </p>
        <p class="nft-worker-profit">
          日利润 ≈ {{ totalPrice.min_asset_24_profit.toFixed(0) }}
          <!-- -{{
            totalPrice.max_asset_24_profit.toFixed(0)
          }} -->
          DCASH
        </p>
      </div>
      <div class="nfts-eachother-price">
        <p>土地等NFT:</p>
        <img
          class="nft-worker-asset"
          src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
        />
        <p>
          <span>{{ totalPrice.nftsTotalPrice.toFixed(4) }} </span>
        </p>
      </div>
      <div class="nfts-eachother-price">
        <p>仓库、订单等物品:</p>
        <img
          class="nft-worker-asset"
          src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
        />
        <p>
          <span>{{ totalPrice.itemsTotalPrice.toFixed(4) }} </span>
        </p>
      </div>
      <div class="nfts-eachother-price">
        <p>正在生产的物品:</p>
        <img
          class="nft-worker-asset"
          src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
        />
        <p>
          <span>{{ totalPrice.taskItemsTotalPrice.toFixed(4) }} </span>
        </p>
      </div>
      <div class="nfts-total-price">
        <p class="tip">
          <span v-if="dcashPrice > 0"
            >* 1DCASH={{ dcashPrice.toFixed(4) }}USDT<br
          /></span>
          * 日利润基于土地等NFT24小时不间断生产预估<br />
          * 总价值由小镇账号的NFT资产根据官方价格预估&nbsp;<van-icon
            name="question-o"
            size="10"
          />
        </p>
      </div>
      <div class="nfts-total-price-tip" v-if="showTip">
        <div v-for="info of infos" :key="info.key">
          <p>{{ info.isNFT ? "一级" : "" }}{{ info.name }}&nbsp;</p>
          <img
            v-if="info.isNFT"
            class="nft-worker-asset"
            :src="tdfsnft.getCollectionPng(this.$store, `${info.key}`)"
          />
          <img
            v-else
            class="nft-worker-asset"
            :src="tdfsnft.getAssetPng(this.$store, `1 ${info.key}`)"
          />
          &nbsp;=&nbsp;
          <img
            class="nft-worker-asset"
            src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
          />
          <p>
            <span>{{ info.price.toFixed(2) }} </span>
          </p>
        </div>
      </div>
    </div>
    <div class="nfts" v-if="coins && coins.length > 0">
      <div class="nft-top">
        <van-tag mark type="primary">{{ account }} 的仓库</van-tag>
        <div>
          <van-button
            v-if="accountName === account && accountName !== ''"
            size="mini"
            type="danger"
            @click="sale"
            >出售</van-button
          ><van-button
            v-if="accountName === account && accountName !== ''"
            size="mini"
            type="success"
            @click="buy"
            >采购</van-button
          >
        </div>
      </div>
      <template v-if="coins">
        <home-NFT-coins
          :coins="coins"
          :orders="recyclableOrders"
          :account="account"
          @reload="reloadData"
          :orderlogs="orderlogs"
        />
      </template>
    </div>

    <!-- <div
      class="nfts"
      v-if="coins && coins.length > 0 && orders && orders.length > 0"
    >
      <template v-if="coins.length > 0 && orders.length > 0">
        <home-orders
          :coins="coins"
          :orders="recyclableOrders"
          :account="account"
        />
      </template>
    </div> -->

    <div class="nfts" v-if="sellOrders && sellOrders.length > 0">
      <van-tag mark type="primary">{{ account }} 的出售订单</van-tag>
      <template v-if="sellOrders">
        <sell-orders :sellOrders="sellOrders" @reload="reloadData" />
      </template>
    </div>
    <div class="nfts" v-if="nfts && nfts.length > 0">
      <div class="nft-top">
        <van-tag mark type="primary">{{ account }} 的NFT </van-tag>
        <div>
          <van-button
            v-if="
              canHarvestTaskIds.length > 0 &&
                accountName === account &&
                accountName !== ''
            "
            size="mini"
            type="danger"
            @click="clickOnHarvestAll"
            >一键收获</van-button
          >
        </div>
      </div>
      <template v-if="nfts">
        <home-NFT
          v-for="nft in nfts"
          :key="nft.id"
          :nft="nft"
          :now="now"
          @reload="reloadData"
          :coins="coins"
          @clickDeposit="reloadCoins"
        />
      </template>
    </div>
    <div class="nfts" v-if="luckysResult && luckysResult.length > 0">
      <van-tag mark type="primary">{{ account }} 的许愿记录</van-tag>
      <template v-if="buyOrders">
        <home-luckys :luckysResult="luckysResult" @reload="reloadData" />
      </template>
    </div>
    <div class="nfts" v-if="buyOrders && buyOrders.length > 0">
      <van-tag mark type="primary">{{ account }} 的采购订单</van-tag>
      <template v-if="buyOrders">
        <buy-orders :buyOrders="buyOrders" @reload="reloadData" />
      </template>
    </div>
    <div class="nfts" v-if="tradeLogs && tradeLogs.length > 0">
      <van-tag mark type="primary">{{ account }} 的历史记录</van-tag>
      <template v-if="tradeLogs">
        <home-trade-logs :tradeLogs="tradeLogs" :account="account" />
      </template>
    </div>

    <van-empty
      v-if="
        (loading === null || loading === false) &&
          ((!coins && !nfts) || (coins.length === 0 && nfts.length === 0))
      "
      description="无数据"
    />
    <dialog-sale-coin
      :coins="coins"
      :dialogShow="dialogShow"
      @hidden="dialogShow = false"
      @reload="reloadData"
    />
    <dialog-buy-coin
      :coins="coins"
      :dialogShowBuy="dialogShowBuy"
      @hidden="dialogShowBuy = false"
      @reload="reloadData"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import dfsnft from "@/utils/dfsnft.js";
import { mapState } from "vuex";
import HomeNFT from "@/components/HomeNFT";
import HomeNFTCoins from "@/components/NFTCoins";
import SellOrders from "@/components/SellOrders";
import BuyOrders from "@/components/BuyOrders";
import DialogSaleCoin from "@/components/DialogSaleCoin";
import DialogBuyCoin from "@/components/DialogBuyCoin";
import HomeTradeLogs from "@/components/HomeTradeLogs";
import scatter from "@/utils/scatter";
import { Toast } from "vant";
import HomeLuckys from "@/components/HomeLuckys";
export default {
  name: "Home",
  components: {
    HomeNFT,
    HomeNFTCoins,
    SellOrders,
    DialogSaleCoin,
    DialogBuyCoin,
    BuyOrders,
    HomeTradeLogs,
    HomeLuckys,
  },
  data() {
    return {
      collapse: [],
      nfts: null,
      loading: null,
      coins: null,
      now: moment(),
      timer: null,
      account: "",
      sellOrders: null,
      buyOrders: null,
      nftAssets: null,
      totalPrice: null,
      showTip: false,
      tdfsnft: dfsnft,
      dialogShow: false,
      dialogShowBuy: false,
      orderlogs: [],
      tradeLogs: [],
      luckysResult: [],
    };
  },
  watch: {
    account() {
      this.nfts = null;
      this.loading = null;
      this.coins = null;
      this.sellOrders = null;
      this.buyOrders = null;
      this.nftAssets = null;
      this.totalPrice = null;
      this.orderlogs = [];
      this.tradeLogs = [];
      this.luckysResult = [];
      localStorage.setItem("home-search-account", this.account);
    },
  },
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      infos: (state) => state.app.info,
      orders: (state) => state.app.orders,
      workers: (state) => state.app.workers,
      farmCrops: (state) => state.app.farmCrops,
      dcashPrice: (state) => state.app.dcashPrice,
      shopPngPre: (state) => state.app.shopPngPre,
      shopConf: (state) => state.app.shopConf,
      gemfactoryWorks: (state) => state.app.gemfactoryWorks,
    }),
    canHarvestTaskIds() {
      var ids = [];
      for (let nft of this.nfts) {
        if (nft.isFinish && nft.task) {
          ids.push(nft.task.id);
        }
      }
      return ids;
    },
    shopConfCollections() {
      return this.shopConf.flatMap((v) => {
        return v.collection;
      });
    },
    recyclableOrders() {
      if (
        !this.coins ||
        this.coins.length === 0 ||
        !this.orders ||
        this.orders.length === 0
      ) {
        return [];
      }
      var result = [];
      for (let order of this.orders) {
        let require_asset = order.require_asset;
        var count = 0;
        for (let ra of require_asset) {
          for (let coin of this.coins) {
            if (coin && coin.balance && coin.balance !== "") {
              if (
                ra.split(" ")[1] === coin.balance.split(" ")[1] &&
                parseFloat(coin.balance.split(" ")[0]) >=
                  parseFloat(ra.split(" ")[0])
              ) {
                count++;
              }
            }
          }
        }
        if (count === require_asset.length) {
          result.push(order);
        }
      }
      return result;
    },
  },
  mounted() {
    this.account = localStorage.getItem("home-search-account")
      ? localStorage.getItem("home-search-account")
      : "";
    if (this.accountName !== "" && this.account === "") {
      this.account = this.accountName;
    }
    if (this.account && this.account !== "") {
      this.reloadData();
    }
  },
  deactivated() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  activated() {
    let that = this;
    this.timer = setInterval(() => {
      that.now = moment();
    }, 1000);
  },
  unmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    toWish() {
      window.location.href = "https://dfsfarms.netlify.app/wish/";
    },
    clickOnHarvestAll() {
      let that = this;
      if (this.canHarvestTaskIds.length > 0) {
        scatter.harvestAll(this.canHarvestTaskIds, (e) => {
          if (scatter.hcb(e)) {
            Toast.success("收获成功");
            that.reloadData();
          }
        });
      }
    },
    sale() {
      this.dialogShow = true;
    },
    buy() {
      this.dialogShowBuy = true;
    },
    viewMyAccount() {
      this.account = this.accountName;
      this.reloadData();
    },
    clickOnShowTip() {
      this.showTip = !this.showTip;
    },
    async reloadCoins() {
      var res = await dfsnft.getCoins(this.account);
      this.coins = res && res.rows ? res.rows : null;
    },
    async reloadData() {
      this.nfts = null;
      this.loading = null;
      this.coins = null;
      this.sellOrders = null;
      this.buyOrders = null;
      this.nftAssets = null;
      this.totalPrice = null;
      this.loading = true;
      this.orderlogs = [];
      this.tradeLogs = [];
      this.luckysResult = [];
      var res = await dfsnft.getWorker();
      res = await dfsnft.getCoins(this.account);
      this.coins = res && res.rows ? res.rows : null;
      res = await dfsnft.getNFT(this.account);
      var nfts =
        res && res.rows
          ? res.rows
              .filter((v) => {
                return this.shopConfCollections.indexOf(v.collection) != -1;
              })
              .map((v) => {
                v.png = this.shopPngPre + v.collection + ".png";
                return v;
              })
          : null;
      this.nfts = [];
      for (let shopConfCollection of this.shopConfCollections) {
        for (let nft of nfts) {
          if (nft.collection === shopConfCollection) {
            this.nfts.push(nft);
          }
        }
      }
      for (let nft of this.nfts) {
        res = await dfsnft.getStats(nft.id);
        if (res && res.rows) {
          for (let stat of res.rows) {
            nft[stat.key] = stat.val;
          }
        }
        res = await dfsnft.getTasks(nft.id);
        if (res && res.rows && res.rows[0]) {
          nft.task = res.rows[0];
          nft.task.value = nft.task.product.split(" ")[0];
          nft.task.farm = nft.task.product.split(" ")[1];
          if (moment().isAfter(moment(nft.task.mature_time).add(8, "h"))) {
            nft.isFinish = true;
          }
        } else {
          nft.notask = true;
        }
      }
      for (let nft of this.nfts) {
        nft.workers = [];
        for (let worker of this.workers) {
          if (
            worker.require_nft_type === nft.collection &&
            nft.level &&
            nft.level >= worker.require_level
          ) {
            nft.workers.push(worker);
          }
        }
      }
      for (let nft of this.nfts) {
        for (let sc of this.shopConf) {
          if (nft.collection === sc.collection) {
            nft.name = sc.name;
          }
        }
      }
      res = await dfsnft.getAccountSellOrders(this.account);
      if (res && res.rows) {
        this.sellOrders = res.rows;
      }
      res = await dfsnft.getAccountBuyOrders(this.account);
      if (res && res.rows) {
        this.buyOrders = res.rows;
      }
      res = await dfsnft.getNftAssets();
      this.nftAssets = res && res.rows ? res.rows : null;
      res = await dfsnft.getOrders();
      this.totalPrice = dfsnft.calcNFTTotalPrice({
        nfts: this.nfts,
        nftAssets: this.nftAssets,
        sellOrders: this.sellOrders,
        coins: this.coins,
        orders: this.orders,
        buyOrders: this.buyOrders,
        farmCrops: this.farmCrops,
        shopConf: this.shopConf,
        workers: this.workers,
        store: this.$store,
      });
      res = await dfsnft.getOrderLogs(this.account);
      if (res && res.rows) {
        this.orderlogs = res.rows;
      }
      res = await dfsnft.getTradeLogs(this.account);
      if (res) {
        this.tradeLogs = res;
      }
      res = await dfsnft.getLuckysResult(this.account);
      if (res && res.rows) {
        this.luckysResult = res.rows;
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .nfts {
    margin: 10px;
    border-top: 4px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0;
    .nft-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .nfts-eachother-price {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    p {
      font-size: 11px;
      color: #949494;
    }
    img {
      width: 18px;
    }
  }
  .nfts-total-price-tip {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
    div {
      margin-right: 20px;
      display: flex;
      align-items: center;
      p {
        font-size: 11px;
        color: #949494;
      }
    }
    img {
      width: 18px;
    }
  }
  .nfts-total-price {
    display: flex;
    align-items: center;
    margin: 4px 0 4px 0;
    img {
      width: 30px;
    }
    p {
      font-size: 13px;
      span {
        font-weight: bold;
      }
    }
    .tip {
      font-size: 11px;
      color: #949494;
      margin-bottom: 4px;
      span {
        font-weight: normal;
      }
    }
    .nft-worker-profit {
      font-size: 11px;
      color: #949494;
      margin-left: 8px;
    }
  }
  .empty-p {
    margin: 4px 0;
    font-size: 13px;
  }
}
</style>
