import scatter from "../../utils/scatter";

const app = {
  state: {
    freecpu: localStorage.getItem("freecpu")
      ? localStorage.getItem("freecpu")
      : true,
    eos: null,
    rpc: null,
    network: null,
    account: null,
    eosnode: localStorage.getItem("eosnode")
      ? JSON.parse(localStorage.getItem("eosnode"))
      : {
          chainId:
            "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
          host: "47.99.40.133",
          port: 443,
          protocol: "https",
          name: "Default",
        },
    nodes: [
      {
        unShowUrl: !0,
        name: "Default",
        area: "production",
        protocol: "https",
        host: "47.99.40.133",
        port: "443",
        url: "https://47.99.40.133",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "Newdex",
        area: "production",
        protocol: "https",
        host: "eos.newdex.one",
        port: "443",
        url: "https://eos.newdex.one",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "Eosflare",
        area: "production",
        protocol: "https",
        host: "api.eosflare.io",
        port: "443",
        url: "https://api.eosflare.io",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "TP",
        area: "production",
        protocol: "https",
        host: "eospush.tokenpocket.pro",
        port: "443",
        url: "https://eospush.tokenpocket.pro",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "Fwnw",
        area: "production",
        protocol: "https",
        host: "api.fwnw.com",
        port: "443",
        url: "https://api.fwnw.com",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "Greymass",
        area: "production",
        protocol: "https",
        host: "eos.greymass.com",
        port: "443",
        url: "https://eos.greymass.com",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "Scatter",
        area: "production",
        protocol: "https",
        host: "nodes.get-scatter.com",
        port: "443",
        url: "https://nodes.get-scatter.com",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "Sweden",
        area: "production",
        protocol: "https",
        host: "api.eossweden.se",
        port: "443",
        url: "https://api.eossweden.se",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "Laomao",
        area: "production",
        protocol: "https",
        host: "api.eoslaomao.com",
        port: "443",
        url: "https://api.eoslaomao.com",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
      {
        name: "Eosn",
        area: "production",
        protocol: "https",
        host: "api.eosn.io",
        port: "443",
        url: "https://api.eosn.io",
        chainId:
          "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
      },
    ],
    sellOrders: {},
    accountName: "",
    marketLists: localStorage.getItem("marketLists")
      ? JSON.parse(localStorage.getItem("marketLists"))
      : [],
    // dcash的价格usdt
    dcashPrice: 0,
    // 计算物品生产消耗的DCASH数量
    workerPrices: {
      DCASH: 1,
    },
    workerPricesDesc: {},
    // 系统的回收订单
    orders: [],
    // 系统的NFT信息
    nftAssets: [],
    // 系统订单回收的物品的价格信息
    coinMaxPriceWorker: {},
    coinMinPriceWorker: {},
    tcoinPrices: {},
    tcoinMaxPrices: {},
    workerPricesUnkown: {},
    // 系统的生成关系
    workers: [],
    // nft、物品的价格信息汇总（根据nft价格、物品的回收价格计算）
    info: [],
    // TODO 新的物品信息
    farmCrops: [
      {
        key: "DCASH",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png",
      },
      {
        key: "WHEAT",
        name: "麦子",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/WHEAT.png",
        typeId: 1,
      },
      {
        key: "CORN",
        name: "玉米",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CORN.png",
        typeId: 1,
      },
      {
        key: "CARROT",
        name: "萝卜",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CARROT.png",
        typeId: 1,
      },
      {
        key: "SUGCANE",
        name: "甘蔗",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/SUGCANE.png",
        typeId: 1,
      },
      {
        key: "COTTON",
        name: "棉花",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/COTTON.png",
        typeId: 1,
      },
      {
        key: "BERRY",
        name: "草莓",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/BERRY.png",
        typeId: 1,
      },
      {
        key: "COCOS",
        name: "可可",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/COCOS.png",
        typeId: 1,
      },
      {
        key: "TOMATO",
        name: "西红柿",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/TOMATO.png",
        typeId: 1,
      },
      {
        key: "MILK",
        name: "牛奶",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/MILK.png",
        typeId: 2,
      },
      {
        key: "EGG",
        name: "鸡蛋",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/EGG.png",
        typeId: 2,
      },
      {
        key: "WOOL",
        name: "羊毛",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/WOOL.png",
        typeId: 2,
      },
      {
        key: "COWFOOD",
        name: "牛饲料",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/COWFOOD.png",
        typeId: 3,
      },
      {
        key: "CHKFOOD",
        name: "鸡饲料",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CHKFOOD.png",
        typeId: 3,
      },
      {
        key: "SHPFOOD",
        name: "羊饲料",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/SHPFOOD.png",
        typeId: 3,
      },
      {
        key: "BREAD",
        name: "面包",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/BREAD.png",
        typeId: 3,
      },
      {
        key: "COOKIE",
        name: "曲奇饼",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/COOKIE.png",
        typeId: 3,
      },
      {
        key: "CAKE",
        name: "蛋糕",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CAKE.png",
        typeId: 3,
      },
      {
        key: "RUBY",
        name: "红宝石",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/RUBY.png",
        typeId: 3,
      },
      {
        key: "BERYL",
        name: "绿宝石",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/BERYL.png",
        typeId: 3,
      },
      {
        key: "TOPAZ",
        name: "黄玉",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/TOPAZ.png",
        typeId: 3,
      },
      {
        key: "AMTHYST",
        name: "紫水晶",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/AMTHYST.png",
        typeId: 3,
      },
      {
        key: "CLOTH",
        name: "棉布",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CLOTH.png",
        typeId: 3,
      },
      {
        key: "YARN",
        name: "纺纱",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/YARN.png",
        typeId: 3,
      },
      {
        key: "SILK",
        name: "丝绸",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/SILK.png",
        typeId: 3,
      },
      {
        key: "SHIRT",
        name: "衬衫",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/SHIRT.png",
        typeId: 3,
      },
      {
        key: "SWEATER",
        name: "毛衣",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/SWEATER.png",
        typeId: 3,
      },
      {
        key: "COAT",
        name: "外套",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/COAT.png",
        typeId: 3,
      },
      {
        key: "SUGAR",
        name: "砂糖",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/SUGAR.png",
        typeId: 3,
      },
      {
        key: "SYRUP",
        name: "糖浆",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/SYRUP.png",
        typeId: 3,
      },
      {
        key: "CARAMEL",
        name: "焦糖",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CARAMEL.png",
        typeId: 3,
      },
      {
        key: "CREAM",
        name: "奶油",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CREAM.png",
        typeId: 3,
      },
      {
        key: "CHEESE",
        name: "奶酪",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CHEESE.png",
        typeId: 3,
      },
      {
        key: "BUTTER",
        name: "黄油",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/BUTTER.png",
        typeId: 3,
      },
      {
        key: "YOGURT",
        name: "酸奶",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/YOGURT.png",
        typeId: 3,
      },
      {
        key: "ICREAM",
        name: "冰淇淋",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/ICREAM.png",
        typeId: 3,
      },
      {
        key: "ILOLLY",
        name: "冰棒",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/ILOLLY.png",
        typeId: 3,
      },
      {
        key: "FROYO",
        name: "冻酸奶",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/FROYO.png",
        typeId: 3,
      },
      {
        key: "FTZ",
        name: "化肥",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/FTZ.png",
        typeId: 4,
      },
      {
        key: "LAND",
        name: "土地体验卡",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/LAND.png",
        typeId: 4,
      },
      {
        key: "VOICE",
        name: "小喇叭",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/VOICE.png",
        typeId: 4,
      },
      {
        key: "CHIP",
        name: "碎片",
        url:
          "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/factory/CHIP.png",
        typeId: 4,
      },
    ],
    shopPngPre: "https://cdn.jsdelivr.net/gh/defis-net/material3/Town/shop/",
    // TODO 新的NFT信息
    shopConf: [
      {
        collection: "land",
        name: "土地",
        type: 1,
      },
      {
        collection: "cow",
        name: "奶牛",
        type: 2,
      },
      {
        collection: "chicken",
        name: "鸡",
        type: 2,
      },
      {
        collection: "sheep",
        name: "绵羊",
        type: 2,
      },
      {
        collection: "bakery",
        name: "面包坊",
        type: 3,
      },
      {
        collection: "feedmill",
        name: "饲料厂",
        type: 3,
      },
      {
        collection: "gemfactory",
        name: "宝石工厂",
        type: 3,
      },
      {
        collection: "textilemill",
        name: "纺织厂",
        type: 3,
      },
      {
        collection: "tailorshop",
        name: "裁缝店",
        type: 3,
      },
      {
        collection: "sugarmill",
        name: "制糖厂",
        type: 3,
      },
      {
        collection: "dairyshop",
        name: "乳制品店",
        type: 3,
      },
      {
        collection: "icecreamshop",
        name: "冰淇淋店",
        type: 3,
      },
    ],
    gemfactoryWorks: [
      {
        create_asset: "1.0000 RUBY",
        require_asset: [
          "https://cdn.jsdelivr.net/gh/defis-net/material2/coin/usdxusdxusdx-usdc.png",
        ],
        require_level: 1,
        require_nft_type: "gemfactory",
        require_time: 3600 * 2,
      },
      {
        create_asset: "1.0000 BERYL",
        require_asset: [
          "https://cdn.jsdelivr.net/gh/defis-net/material2/coin/dfxtokenmain-dfg.png",
        ],
        require_level: 1,
        require_nft_type: "gemfactory",
        require_time: 3600 * 2,
      },
      {
        create_asset: "1.0000 TOPAZ",
        require_asset: [
          "https://cdn.jsdelivr.net/gh/defis-net/material2/coin/tagtokenmain-tag.png",
        ],
        require_level: 1,
        require_nft_type: "gemfactory",
        require_time: 3600 * 2,
      },
    ],
    // TODO 物品的升级规则
    levelup: [
      {
        collection: "land",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} RUBY`];
        },
        p: 2,
      },
      {
        collection: "cow",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} TOPAZ`];
        },
        p: 2,
      },
      {
        collection: "chicken",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} TOPAZ`];
        },
        p: 2,
      },
      {
        collection: "sheep",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} TOPAZ`];
        },
        p: 2,
      },
      {
        collection: "bakery",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} BERYL`];
        },
        p: 2,
      },
      {
        collection: "feedmill",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} BERYL`];
        },
        p: 2,
      },
      {
        collection: "gemfactory",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} BERYL`];
        },
        p: 2,
      },
      {
        collection: "textilemill",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} BERYL`];
        },
        p: 2,
      },
      {
        collection: "tailorshop",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} BERYL`];
        },
        p: 2,
      },
      {
        collection: "sugarmill",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} BERYL`];
        },
        p: 2,
      },
      {
        collection: "dairyshop",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} BERYL`];
        },
        p: 2,
      },
      {
        collection: "icecreamshop",
        needs: [],
        genNeeds: (level) => {
          return ["120 DCASH", `${10 + (level - 1) * 2} BERYL`];
        },
        p: 2,
      },
    ],
  },
  mutations: {
    SET_SellOrders: (state, { k, sellOrders }) => {
      sellOrders.price =
        parseFloat(sellOrders.cash) / parseFloat(sellOrders.item);
      state.sellOrders[k] = sellOrders;
    },
    SET_EosInfo: (state, eosinfo) => {
      if (eosinfo) {
        if (eosinfo.eos) {
          state.eos = eosinfo.eos;
        }
        if (eosinfo.rpc) {
          state.rpc = eosinfo.rpc;
        }
        if (eosinfo.network) {
          state.network = eosinfo.network;
        }
        if (eosinfo.account) {
          state.account = eosinfo.account;
          state.accountName =
            eosinfo.account && eosinfo.account.name ? eosinfo.account.name : "";
          if (state.accountName !== "eostomoon555") {
            // state.accountName = "";
          }
        }
      }
    },
    SET_MarketLists: (state, marketLists) => {
      state.marketLists = marketLists;
      localStorage.setItem("marketLists", JSON.stringify(marketLists));
    },
    SET_DcashPrice: (state, price) => {
      state.dcashPrice = price;
    },
    SET_WorkerPrices: (state, { workerPrices, workerPricesDesc }) => {
      state.workerPrices = workerPrices;
      state.workerPricesDesc = workerPricesDesc;
    },
    SET_Workers: (state, workers) => {
      state.workers = workers;
    },
    SET_Orders: (state, orders) => {
      state.orders = orders;
    },
    SET_TcoinPrices: (state, tcoinPrices) => {
      state.tcoinPrices = tcoinPrices;
    },
    SET_TcoinMaxPrices: (state, tcoinMaxPrices) => {
      state.tcoinMaxPrices = tcoinMaxPrices;
    },
    SET_NftAssets: (state, nftAssets) => {
      state.nftAssets = nftAssets;
    },
    SET_Info: (state, info) => {
      state.info = info;
    },
    SET_Freecpu: (state, freecpu) => {
      state.freecpu = freecpu;
      localStorage.setItem("freecpu", freecpu);
    },
    SET_CoinPriceWorker: (state, v) => {
      state.coinMinPriceWorker = v.min;
      state.coinMaxPriceWorker = v.max;
    },
    SET_Eosnode: (state, name) => {
      console.log(state.nodes);
      let r = state.nodes.filter((v) => {
        return v.name === name;
      });
      if (r && r.length > 0) {
        state.eosnode = r[0];
        localStorage.setItem("eosnode", JSON.stringify(r[0]));
        scatter.toggleRpc();
      }
    },
  },
  actions: {
    setEosnode({ commit }, name) {
      commit("SET_Eosnode", name);
    },
    setSellOrders({ commit }, { k, sellOrders }) {
      commit("SET_SellOrders", { k, sellOrders });
      // 计算NFT的价格
      // var info = this.state.app.info;
      // info = info.map((v) => {
      //   if (v.key === k) {
      //     v.price = Math.max(
      //       v.price,
      //       parseFloat(sellOrders.cash) / parseFloat(sellOrders.item)
      //     );
      //   }
      //   return v;
      // });
      // commit("SET_Info", info);
    },
    setEosInfo({ commit }, eosInfo) {
      commit("SET_EosInfo", eosInfo);
    },
    setNftAssets({ commit }, nftAssets) {
      commit("SET_NftAssets", nftAssets);
      // 计算NFT的价格
      var info = this.state.app.info;
      for (let sc of this.state.app.shopConf) {
        var nftPrice_t = 0;
        for (let nftAsset of nftAssets) {
          if (nftAsset.collection === sc.collection) {
            // 一级NFT价格
            nftPrice_t = 100 + nftAsset.supply / 10;
            break;
          }
        }
        let i = info.filter((v) => {
          return v.key === sc.collection;
        });
        if (i && i.length > 0) {
          i[0] = {
            key: sc.collection,
            name: sc.name,
            price: nftPrice_t,
            isNFT: true,
          };
        } else {
          info.push({
            key: sc.collection,
            name: sc.name,
            price: nftPrice_t,
            isNFT: true,
          });
        }
      }
      commit("SET_Info", info);
    },
    setFreecpu({ commit }, freecpu) {
      commit("SET_Freecpu", freecpu);
    },
    setWorkerPrices({ commit }, { workerPrices, workerPricesDesc }) {
      commit("SET_WorkerPrices", { workerPrices, workerPricesDesc });
    },
    setMarketLists({ commit }, marketLists) {
      commit("SET_MarketLists", marketLists);
    },
    setDcashPrice({ commit }, price) {
      commit("SET_DcashPrice", price);
    },
    setOrders({ commit }, orders) {
      commit("SET_Orders", orders);
      // TODO 系统最低、最高的回收价格计算
      var tcoinPrices = {
        DCASH: 1,
        RUBY: 1.05,
        BERYL: 1.05,
        TOPAZ: 1.05,
      };
      var tcoinMaxPrices = {
        DCASH: 1,
        RUBY: 1.05,
        BERYL: 1.05,
        TOPAZ: 1.05,
      };
      var coinMaxPriceWorker = {};
      var coinMinPriceWorker = {};
      for (let order of this.state.app.orders) {
        var per = 0;
        for (let ra of order.require_asset) {
          per +=
            this.state.app.workerPrices[ra.split(" ")[1]] *
            parseFloat(ra.split(" ")[0]);
        }
        for (let ra of order.require_asset) {
          let ra_per =
            (this.state.app.workerPrices[ra.split(" ")[1]] *
              parseFloat(ra.split(" ")[0])) /
            per;

          // TODO pay + 许愿池宝石(3dcash)
          let pay = parseFloat(order.pay) + 0;

          if (tcoinPrices[ra.split(" ")[1]]) {
            if (
              (pay * ra_per) / parseFloat(ra.split(" ")[0]) <
              tcoinPrices[ra.split(" ")[1]]
            ) {
              coinMinPriceWorker[ra.split(" ")[1]] = order;
              coinMinPriceWorker[ra.split(" ")[1]].p =
                (pay * ra_per) / parseFloat(ra.split(" ")[0]);
            }
          } else {
            coinMinPriceWorker[ra.split(" ")[1]] = order;
            coinMinPriceWorker[ra.split(" ")[1]].p =
              (pay * ra_per) / parseFloat(ra.split(" ")[0]);
          }

          if (tcoinMaxPrices[ra.split(" ")[1]]) {
            if (
              (pay * ra_per) / parseFloat(ra.split(" ")[0]) >
              tcoinMaxPrices[ra.split(" ")[1]]
            ) {
              coinMaxPriceWorker[ra.split(" ")[1]] = order;
              coinMaxPriceWorker[ra.split(" ")[1]].p =
                (pay * ra_per) / parseFloat(ra.split(" ")[0]);
            }
          } else {
            coinMaxPriceWorker[ra.split(" ")[1]] = order;
            coinMaxPriceWorker[ra.split(" ")[1]].p =
              (pay * ra_per) / parseFloat(ra.split(" ")[0]);
          }

          // 按最低
          tcoinPrices[ra.split(" ")[1]] = tcoinPrices[ra.split(" ")[1]]
            ? Math.min(
                (pay * ra_per) / parseFloat(ra.split(" ")[0]),
                tcoinPrices[ra.split(" ")[1]]
              )
            : (pay * ra_per) / parseFloat(ra.split(" ")[0]);
          // 按最高算
          tcoinMaxPrices[ra.split(" ")[1]] = tcoinMaxPrices[ra.split(" ")[1]]
            ? Math.max(
                (pay * ra_per) / parseFloat(ra.split(" ")[0]),
                tcoinMaxPrices[ra.split(" ")[1]]
              )
            : (pay * ra_per) / parseFloat(ra.split(" ")[0]);
        }
      }
      // TODO 每次更新需要人工计算系统不回收的物品的订单回收价格
      // 棉花
      if (!tcoinPrices["COTTON"]) {
        tcoinPrices["COTTON"] = this.state.app.workerPrices["COTTON"];
      }
      if (!tcoinMaxPrices["COTTON"]) {
        tcoinMaxPrices["COTTON"] = this.state.app.workerPrices["COTTON"];
      }
      // 系统不回收的 手动拆分计算
      // 饲料
      if (!tcoinPrices["COWFOOD"]) {
        tcoinPrices["COWFOOD"] = tcoinPrices["WHEAT"] * 2 + tcoinPrices["CORN"];
      }
      if (!tcoinPrices["CHKFOOD"]) {
        tcoinPrices["CHKFOOD"] =
          tcoinPrices["WHEAT"] * 2 + tcoinPrices["CARROT"];
      }
      if (!tcoinPrices["SHPFOOD"]) {
        tcoinPrices["SHPFOOD"] =
          tcoinPrices["WHEAT"] + tcoinPrices["CORN"] + tcoinPrices["CARROT"];
      }
      // 衣服
      // 羊毛
      if (!tcoinPrices["WOOL"]) {
        tcoinPrices["WOOL"] = tcoinPrices["SHPFOOD"];
      }
      // 棉布
      if (!tcoinPrices["CLOTH"]) {
        tcoinPrices["CLOTH"] = tcoinPrices["COTTON"] * 3;
      }
      // 纺纱
      if (!tcoinPrices["YARN"]) {
        tcoinPrices["YARN"] = tcoinPrices["WOOL"] * 3;
      }
      // 丝绸
      if (!tcoinPrices["SILK"]) {
        tcoinPrices["SILK"] =
          tcoinPrices["WOOL"] * 2 + tcoinPrices["COTTON"] * 2;
      }
      if (!tcoinPrices["AMTHYST"]) {
        tcoinPrices["AMTHYST"] =
          tcoinPrices["RUBY"] + tcoinPrices["BERYL"] + tcoinPrices["TOPAZ"];
      }

      for (let farmCrop of this.state.app.farmCrops) {
        if (farmCrop.typeId && !tcoinPrices[farmCrop.key]) {
          tcoinPrices[farmCrop.key] = this.state.app.workerPrices[farmCrop.key]
            ? this.state.app.workerPrices[farmCrop.key]
            : 0;
        }
      }

      // 最高的价格
      tcoinMaxPrices["COWFOOD"] =
        tcoinMaxPrices["WHEAT"] * 2 + tcoinMaxPrices["CORN"];
      tcoinMaxPrices["CHKFOOD"] =
        tcoinMaxPrices["WHEAT"] * 2 + tcoinMaxPrices["CARROT"];
      tcoinMaxPrices["SHPFOOD"] =
        tcoinMaxPrices["WHEAT"] +
        tcoinMaxPrices["CORN"] +
        tcoinMaxPrices["CARROT"];
      if (!tcoinMaxPrices["WOOL"]) {
        tcoinMaxPrices["WOOL"] = tcoinMaxPrices["SHPFOOD"];
      }
      tcoinMaxPrices["CLOTH"] = tcoinMaxPrices["COTTON"] * 3;
      tcoinMaxPrices["YARN"] = tcoinMaxPrices["WOOL"] * 3;
      tcoinMaxPrices["SILK"] =
        tcoinMaxPrices["WOOL"] * 2 + tcoinMaxPrices["COTTON"] * 2;
      if (!tcoinMaxPrices["AMTHYST"]) {
        tcoinMaxPrices["AMTHYST"] =
          tcoinMaxPrices["RUBY"] +
          tcoinMaxPrices["BERYL"] +
          tcoinMaxPrices["TOPAZ"];
      }

      for (let farmCrop of this.state.app.farmCrops) {
        if (farmCrop.typeId && !tcoinMaxPrices[farmCrop.key]) {
          tcoinMaxPrices[farmCrop.key] = this.state.app.workerPrices[
            farmCrop.key
          ]
            ? this.state.app.workerPrices[farmCrop.key]
            : 0;
        }
      }

      commit("SET_TcoinPrices", tcoinPrices);
      commit("SET_TcoinMaxPrices", tcoinMaxPrices);
      commit("SET_CoinPriceWorker", {
        min: coinMinPriceWorker,
        max: coinMaxPriceWorker,
      });
      // 写入材料的价格
      var info = this.state.app.info;
      for (let key of Object.keys(tcoinPrices)) {
        let fc = this.state.app.farmCrops.filter((v) => {
          return v.key === key;
        });
        if (fc && fc[0] && fc[0].name) {
          let i = info.filter((v) => {
            return v.key === key;
          });
          if (i && i.length > 0) {
            i[0] = {
              key: key,
              name: fc[0].name,
              price: tcoinPrices[key],
            };
          } else {
            info.push({
              key: key,
              name: fc[0].name,
              price: tcoinPrices[key],
            });
          }
        }
      }
    },
    setWorkers({ commit }, workers) {
      let findName = (asset) => {
        if (asset === "DCASH") {
          return asset;
        }
        const result = this.state.app.farmCrops.filter((v) => {
          return v.key === asset;
        });
        if (result && result.length > 0) {
          return result[0].name;
        }
        return asset;
      };
      let w = JSON.parse(JSON.stringify(this.state.app.gemfactoryWorks)).concat(
        workers
      );
      commit("SET_Workers", w);
      // 更新系统任务的价格
      let workerPrices = {
        DCASH: 1,
      }; // this.state.app.workerPrices;
      let workerPricesDesc = {};
      for (let worker of w) {
        let k = worker.create_asset.split(" ")[1];
        let v = parseFloat(worker.create_asset.split(" ")[0]);
        let time = worker.require_time;
        if (!workerPrices[k]) {
          workerPrices[k] = 0;
        }
        for (let asset of worker.require_asset) {
          if (!workerPricesDesc[findName(k)]) {
            workerPricesDesc[findName(k)] = {
              k: k,
              x: ``,
              y: time,
              z: 24 / (time / (60 * 60)),
            };
          }
          if (asset.indexOf(" ") != -1) {
            workerPricesDesc[findName(k)].x +=
              `${findName(asset.split(" ")[1])} * ${parseFloat(
                asset.split(" ")[0]
              )}` + " + ";
            workerPrices[k] +=
              workerPrices[asset.split(" ")[1]] *
              parseFloat(asset.split(" ")[0]);
          } else {
            // 计算宝石消耗的DCASH
            workerPrices[k] = 1;
            // this.state.app.dcashPrice === 0
            //   ? 1
            //   : 1 / this.state.app.dcashPrice; // 1usdt
          }
        }
        workerPrices[k] = workerPrices[k] / parseFloat(v);
        // 计算耗时
        // console.log(workerPrices);
        this.dispatch("setWorkerPrices", { workerPrices, workerPricesDesc });
      }
    },
  },
};

export default app;
