import ScatterJS from "@scatterjs/core";
import ScatterEOS from "@scatterjs/eosjs2";
import { JsonRpc } from "eosjs";
import Eos from "eosjs-without-sort"; // 代签不排序
import axios from "axios";
// import { Toast } from "vant";
import { Dialog } from "vant";

ScatterJS.plugins(new ScatterEOS());

class Scatter {
  constructor() {
    this.store = null;
    this.scatter = null;
    this.isConnect = false;
    this.eosJs = null;
    this.connectCount = 0;
  }
  toggleRpc() {
    let network = ScatterJS.Network.fromJson({
      blockchain: "eos",
      chainId: this.store.getters.app.eosnode.chainId,
      host: this.store.getters.app.eosnode.host,
      port: this.store.getters.app.eosnode.port,
      protocol: this.store.getters.app.eosnode.protocol,
    });
    let rpc = new JsonRpc(network.fullhost());
    this.store.dispatch("setEosInfo", {
      network,
      rpc,
    });
  }
  // scatter 初始化
  scatterInit(callback) {
    const self = this;
    if (self.isConnect) {
      if (callback) {
        callback();
      }
      return;
    }
    ScatterJS.connect("dfsfarms-tool", {
      network: this.store.getters.app.network,
    }).then(async (connected) => {
      self.isConnect = connected;
      if (!connected) {
        self.connectCount += 1;
        if (self.connectCount > 10) {
          return false;
        }
        setTimeout(() => {
          self.scatterInit(callback);
        }, 500);
        return false;
      }
      self.scatter = ScatterJS.scatter;
      self.eosJs = ScatterJS.eos(self.store.getters.app.network, Eos, {});
      self.store.dispatch("setEosInfo", {
        eos: self.eosJs,
      });
      window.eosJs = self.eosJs;
      if (callback) {
        callback();
      }
    });
  }
  loginOut(cb) {
    const self = this;
    self.scatter.forgetIdentity();
    if (cb) {
      cb();
    }
  }
  // login
  login(callback) {
    const self = this;
    if (!self.isConnect) {
      self.scatterInit(() => {
        self.login();
      });
      return;
    }
    self.scatter.login().then((id) => {
      if (!id) return console.error("no identity");
      let account = ScatterJS.account("eos");
      self.store.dispatch("setEosInfo", {
        account,
      });
      if (callback) {
        callback(account);
      }
    });
  }

  pushFreeCpu2(params) {
    return new Promise((resolve, reject) => {
      axios.post(`https://api.yfc.one/account/pushaction2`, params).then(
        (res) => {
          let result = Object.assign(res.data, {});
          resolve({ status: result.code === 200, result });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  handleUseFreeCpu(tx, cb) {
    (async () => {
      try {
        const txh = {
          blocksBehind: 3,
          expireSeconds: 120,
        };
        const data = {
          tx,
          txh,
        };
        const formName = this.store.state.app.account.name;
        tx.actions.unshift({
          account: "dfsfreecpu11",
          name: "freecpu",
          authorization: [
            {
              actor: "yfcmonitor11",
              permission: `active`,
            },
          ],
          data: {
            user: formName,
          },
        });
        let pushTransactionArgs = await this.eosJs.transaction(tx, {
          sign: true,
          broadcast: false,
        });
        const p = pushTransactionArgs;
        const l = p.transaction.transaction;
        l.signatures = p.transaction.signatures;
        l.context_free_data = [];
        data.sign_data = l;

        // console.log(tx);
        // console.log(data);

        const { status, result } = await this.pushFreeCpu2(data);
        // console.log(result, status);
        if (!status || !result) {
          // 请求失败 - 走正常流程操作
          cb(result, null);
          return;
        }
        cb(null, result);
      } catch (error) {
        // console.log(error);
        this.dealFreeCpuError(error, cb);
      }
    })();
  }
  dealFreeCpuError(error, cb) {
    let err = error.toString();
    if (typeof error === "object") {
      err = JSON.stringify(error);
    }
    let back = {
      code: 999,
      message: err,
    };
    if (err.indexOf("INSUFFICIENT_OUTPUT_AMOUNT") !== -1) {
      back = {
        code: 3050003,
        message: "滑点过高",
      };
    }
    if (err.indexOf("you have no permission for this operation") !== -1) {
      back = {
        code: 3050003,
        message: "您没有权限操作",
      };
    }
    cb(back, null);
  }
  dealError(e, callback) {
    //  catch 错误回调 ---- code: 3080004 - cpu不足 | 3080002 - net不足 | 3080001 - ram不足
    let back = {
      code: 999,
      message: "fails!",
    };
    try {
      if (typeof e === "object") {
        if (e.code === 402) {
          back = {
            code: "402",
            message: "User rejected the signature request",
          };
        }
      }
      if (typeof e === "string") {
        const err = JSON.parse(e);
        // CPU 不足
        if (err.error.code === 3080004) {
          back = {
            code: 3080004,
            message: "Insufficient CPU resources",
          };
        }
        // NET 不足
        if (err.error.code === 3080002) {
          back = {
            code: 3080002,
            message: "Insufficient Net resources",
          };
        }
        // RAM 不足
        if (err.error.code === 3080001) {
          back = {
            code: 3080001,
            message: "Insufficient RAM resources",
          };
        }
        // 交易超时
        if (err.error.code === 3080006) {
          back = {
            code: 3080006,
            message: this.vthis.$t("error.timeout"),
          };
        }
        if (err.error.code === 3050003 || err.error.code === 3010010) {
          // 滑点过高导致
          const detail = err.error.details;
          if (detail[0].message.indexOf("INSUFFICIENT_OUTPUT_AMOUNT") !== -1) {
            back = {
              code: 3050003,
              message: "滑点过高",
            };
          } else if (
            detail[0].message.indexOf("Invalid packed transaction") !== -1
          ) {
            // 用户取消操作
            back = {
              code: 402,
              message: "用户取消",
            };
          } else {
            back = {
              code: err.error.code,
              message: detail[0].message,
            };
          }
        }
      }
      callback(back, null);
    } catch (error) {
      if (e === "操作已取消") {
        back = {
          code: 402,
          message: "Cancel",
        };
      }
      callback(back, null);
    }
  }
  getFreeCPU() {
    return {
      account: "dfsfreecpu11",
      name: "freecpu",
      authorization: [
        {
          actor: "yfcmonitor11",
          permission: "active",
        },
      ],
      data: {
        user: this.store.getters.app.accountName,
      },
    };
  }
  getAuthorization() {
    if (this.store && this.store.getters.app.account) {
      return [
        {
          actor: this.store.getters.app.account.name,
          permission: this.store.getters.app.account.authority,
        },
      ];
    } else {
      return [];
    }
  }
  getBlocks() {
    return {
      sign: true,
      broadcast: false,
      //   blocksBehind: 3,
      //   expireSeconds: 30,
    };
  }

  hcb(err) {
    if (err && err.code == 402) {
      return false;
    }
    if (err) {
      Dialog.alert({
        title: "提示",
        message: err.message
          ? err.message
          : err.msg
          ? err.msg
          : "network error",
      });
      //   Toast.fail(
      //     err.message ? err.message : err.msg ? err.msg : "network error"
      //   );
      return false;
    }
    return true;
  }
  // ========================== 小镇操作 ==========================
  transfer(params, cb) {
    if (
      this.store.getters.app.freecpu === true ||
      this.store.getters.app.freecpu === "true"
    ) {
      this.handleUseFreeCpu(params, cb);
      return;
    }
    this.eosJs
      .transaction(params, {
        blocksBehind: 3,
        expireSeconds: 30,
      })
      .then((res) => {
        cb(null, res);
      })
      .catch((e) => {
        this.dealError(e, cb);
      });
  }
  produce({ workid, multiple, assetid, round, quantity }, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [],
      };
      for (let q of quantity) {
        params.actions.push({
          account: "dfsfarmitems",
          name: "transfer",
          authorization: this.getAuthorization(),
          data: {
            quantity: q,
            memo: "deposit",
            to: "dfsmetaverse",
            from: this.store.getters.app.account.name,
          },
        });
      }
      params.actions.push({
        account: "dfsmetaverse",
        name: "produce2",
        authorization: this.getAuthorization(),
        data: {
          workid,
          multiple,
          assetid,
          round,
          user: this.store.getters.app.account.name,
        },
      });
      this.transfer(params, cb);
    }
  }
  orderSell({ quantity, orderid }, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [],
      };
      params.actions.push({
        account: "dfsfarmitems",
        name: "transfer",
        authorization: this.getAuthorization(),
        data: {
          quantity: quantity,
          memo: "takesell:" + orderid,
          to: "dfsftsmarket",
          from: this.store.getters.app.account.name,
        },
      });
      this.transfer(params, cb);
    }
  }
  sale({ quantity, price }, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [],
      };
      params.actions.push({
        account: "dfsfarmitems",
        name: "transfer",
        authorization: this.getAuthorization(),
        data: {
          quantity: quantity,
          memo: "sell:DCASH:" + price,
          to: "dfsftsmarket",
          from: this.store.getters.app.account.name,
        },
      });
      this.transfer(params, cb);
    }
  }
  buy({ quantity, price, key }, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [],
      };
      params.actions.push({
        account: "dfsfarmitems",
        name: "transfer",
        authorization: this.getAuthorization(),
        data: {
          quantity: quantity,
          memo: "buy:" + key + ":" + price,
          to: "dfsftsmarket",
          from: this.store.getters.app.account.name,
        },
      });
      this.transfer(params, cb);
    }
  }
  recoveryOrder(order, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [],
      };
      for (let ra of order.require_asset) {
        params.actions.push({
          account: "dfsfarmitems",
          name: "transfer",
          authorization: this.getAuthorization(),
          data: {
            quantity: ra,
            memo: "deposit",
            to: "dfsmetaverse",
            from: this.store.getters.app.account.name,
          },
        });
      }
      params.actions.push({
        account: "dfsmetaverse",
        name: "delivery",
        authorization: this.getAuthorization(),
        data: {
          id: order.id,
          user: this.store.getters.app.account.name,
        },
      });
      this.transfer(params, cb);
    }
  }
  orderBuy({ quantity, orderid }, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [],
      };
      params.actions.push({
        account: "dfsfarmitems",
        name: "transfer",
        authorization: this.getAuthorization(),
        data: {
          quantity: quantity,
          memo: "takebuy:" + orderid,
          to: "dfsftsmarket",
          from: this.store.getters.app.account.name,
        },
      });
      this.transfer(params, cb);
    }
  }
  cancelOrder(oid, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [
          {
            account: "dfsftsmarket",
            name: "cancelsell",
            authorization: this.getAuthorization(),
            data: {
              oid,
            },
          },
        ],
      };
      this.transfer(params, cb);
    }
  }
  cancelBuyOrder(oid, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [
          {
            account: "dfsftsmarket",
            name: "cancelbuy",
            authorization: this.getAuthorization(),
            data: {
              oid,
            },
          },
        ],
      };
      this.transfer(params, cb);
    }
  }
  harvestAll(taskIds, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      var actions = [];
      for (let taskId of taskIds) {
        actions.push({
          account: "dfsmetaverse",
          name: "harvest",
          authorization: this.getAuthorization(),
          data: {
            taskid: taskId,
          },
        });
      }
      let params = {
        actions,
      };
      this.transfer(params, cb);
    }
  }
  harvest(taskid, cb) {
    if (
      this.store &&
      this.store.getters.app.eos &&
      this.store.getters.app.account
    ) {
      let params = {
        actions: [
          {
            account: "dfsmetaverse",
            name: "harvest",
            authorization: this.getAuthorization(),
            data: {
              taskid,
            },
          },
        ],
      };
      this.transfer(params, cb);
    }
  }
}
const scatter = new Scatter();
export default scatter;
