<template>
  <div v-if="buyOrders" class="buyorders-container">
    <div
      class="buyorders-wrap"
      v-for="buyOrder of buyOrders"
      :key="buyOrder.oid"
    >
      <img :src="dfsnft.getAssetPng(this.$store, buyOrder.item)" />
      <p class="nft-get-count">* {{ parseFloat(buyOrder.item).toFixed(0) }}</p>
      <div class="nft-price">
        单价:<img
          class="nft-worker-asset"
          src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
          data-v-74bc2eb2=""
        /><span
          >{{
            (parseFloat(buyOrder.cash) / parseFloat(buyOrder.item)).toFixed(2)
          }}
        </span>
      </div>
      <div class="nft-price-total">
        总出价:<img
          class="nft-worker-asset"
          src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
          data-v-74bc2eb2=""
        />{{ parseFloat(buyOrder.cash).toFixed(2) }}
      </div>
      <van-button
        v-if="accountName === buyOrder.owner"
        type="danger"
        size="mini"
        @click="clickOnCancelOrder(buyOrder)"
        >取消订单</van-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import dfsnft from "@/utils/dfsnft";
import scatter from "@/utils/scatter";
import { Toast } from "vant";

export default {
  name: "BuyOrders",
  props: {
    buyOrders: null,
  },
  data() {
    return {
      dfsnft,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
    }),
  },
  methods: {
    moment,
    clickOnCancelOrder(order) {
      let that = this;
      scatter.cancelBuyOrder(order.oid, (e) => {
        if (scatter.hcb(e)) {
          Toast.success("取消成功");
          that.$emit("reload");
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.buyorders-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  .buyorders-wrap {
    display: flex;
    align-items: center;
  }
  img {
    width: 30px;
  }
  .nft-get-count {
    font-size: 13px;
    font-weight: bold;
  }
  .nft-price {
    margin-left: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
    }
    img {
      width: 20px;
    }
  }
  .nft-price-total {
    margin-left: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    img {
      width: 24px;
    }
    margin-right: 6px;
  }
}
</style>
