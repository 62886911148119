<template>
  <!-- <div> -->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <tabbar />
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
import Tabbar from "@/components/Tabbar";
import scatter from "@/utils/scatter";
import { Dialog } from "vant";

export default {
  name: "APP",
  components: {
    Tabbar,
  },
  data() {
    return {};
  },
  mounted() {
    scatter.login();
    const nowVersion = "v0.2.4";
    const version = localStorage.getItem("version");
    if (!version || version !== nowVersion) {
      Dialog.alert({
        title: "提示",
        message: "如果无法加载内容，请到【其他】页面切换节点",
      }).then(() => {
        localStorage.setItem("version", nowVersion);
      });
    }
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 960px;
  margin: auto;
  background-color: #f3f3f3;
  min-height: calc(100vh - 50px - constant(safe-area-inset-bottom));
  padding-bottom: calc(50px + constant(safe-area-inset-bottom));
  min-height: calc(100vh - 50px - env(safe-area-inset-bottom));
  padding-bottom: calc(50px + env(safe-area-inset-bottom));
}
p {
  margin: 0;
}
</style>
