<template>
  <div v-if="tradeLogs" class="tradelogs-container">
    <div
      class="sellorders-wrap"
      v-for="tradeLog of tradeLogs"
      :key="tradeLog.oid"
    >
      <div class="sellorders-top">
        <van-tag v-if="tradeLog.buyer === account" type="success">购买</van-tag>
        <van-tag v-else type="danger">出售</van-tag>
        <img :src="dfsnft.getAssetPng(this.$store, tradeLog.item)" />
        <p class="nft-get-count">
          * {{ parseFloat(tradeLog.item).toFixed(0) }}
        </p>
        <div class="nft-price">
          单价:<img
            class="nft-worker-asset"
            src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
            data-v-74bc2eb2=""
          /><span
            >{{
              (parseFloat(tradeLog.cash) / parseFloat(tradeLog.item)).toFixed(2)
            }}
          </span>
        </div>
        <div class="nft-price-total">
          总售价:<img
            class="nft-worker-asset"
            src="https://cdn.jsdelivr.net/gh/defis-net/material3/Town-img/farm/dcash.png"
            data-v-74bc2eb2=""
          />{{ parseFloat(tradeLog.cash).toFixed(2) }}
        </div>
      </div>
      <p>
        时间:
        {{
          moment(tradeLog.time)
            .add(8, "h")
            .format("YYYY-MM-DD HH:mm:ss")
        }}&nbsp;&nbsp;&nbsp;
        {{
          tradeLog.buyer === account
            ? `出售者: ${tradeLog.seller}`
            : `购买者: ${tradeLog.buyer}`
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import dfsnft from "@/utils/dfsnft";
import scatter from "@/utils/scatter";
import { Toast } from "vant";

export default {
  name: "HomeTradeLogs",
  props: {
    tradeLogs: null,
    account: null,
  },
  data() {
    return {
      dfsnft,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
    }),
  },
  methods: {
    moment,
    clickOnCancelOrder(order) {
      let that = this;
      scatter.cancelOrder(order.oid, (e) => {
        if (scatter.hcb(e)) {
          Toast.success("取消成功");
          that.$emit("reload");
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.tradelogs-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  .sellorders-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
    .sellorders-top {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  img {
    margin-left: 3px;
    width: 20px;
  }
  p {
    font-size: 11px;
  }
  .nft-get-count {
    font-size: 11px;
    font-weight: bold;
  }
  .nft-price {
    margin-left: 8px;
    font-size: 11px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
    }
    img {
      width: 20px;
    }
  }
  .nft-price-total {
    margin-left: 8px;
    font-size: 11px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
    }
    margin-right: 6px;
  }
}
</style>
