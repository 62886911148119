<template>
  <van-overlay :show="show">
    <div class="nft-buy-coin-container">
      <div class="nft-create">
        <p class="nft-create-title">采购:</p>
        <van-radio-group v-model="buy" direction="horizontal">
          <van-radio
            v-for="coin of targetCoins"
            :key="coin.balance"
            icon-size="14"
            :name="coin.balance"
            ><div class="nft-create-asset">
              <p>{{ coin.name }}</p>
              <img :src="tdfsnft.getAssetPng(null, coin.balance)" /></div
          ></van-radio>
        </van-radio-group>
      </div>
      <div class="nft-create">
        <p class="nft-create-title">数量:</p>
        <van-stepper v-model="count" min="0" :max="99999" />
      </div>
      <div class="nft-create">
        <p class="nft-create-title">出价:</p>
        <van-field :border="false" v-model="price" type="number" label="" />
      </div>
      <div class="nft-create">
        <p class="nft-create-title" style="margin-bottom: 8px;">
          总出价:&nbsp;&nbsp;{{ maxprice }}
        </p>
      </div>

      <div class="nft-create">
        <van-button type="primary" size="small" @click="clickSale"
          >确定采购</van-button
        >
        <van-button type="danger" size="small" @click="hidden">取消</van-button>
      </div>
    </div> </van-overlay
  ><van-popup v-model="showPicker" round position="bottom">
    <van-picker :columns="columns" @cancel="showPicker = false" />
  </van-popup>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import scatter from "@/utils/scatter";
import dfsnft from "@/utils/dfsnft";
import { Toast, Dialog } from "vant";

export default {
  emits: ["hidden", "reload"],
  name: "DialogBuyCoin",
  props: {
    dialogShowBuy: {
      type: Boolean,
      default: false,
    },
    coins: null,
  },
  data() {
    return {
      show: false,
      buy: "",
      count: 0,
      price: 0,
      tdfsnft: dfsnft,
      value: "",
      showPicker: false,
    };
  },
  watch: {
    dialogShowBuy() {
      this.show = this.dialogShowBuy;
      if (this.show) {
        this.count = 0;
        this.price = 0;
      }
      if (this.buy === "" && this.targetCoins.length > 0) {
        this.buy = this.targetCoins[0].balance;
      }
    },
  },
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
      workers: (state) => state.app.workers,
    }),
    maxprice() {
      return (this.count * this.price).toFixed(4);
    },
    buyCoin() {
      for (let coin of this.targetCoins) {
        if (coin.balance === this.buy) {
          return coin;
        }
      }
      return null;
    },
    targetCoins() {
      if (!this.coins) {
        return [];
      }
      return this.farmCrops
        .map((v) => {
          let t = this.coins.filter((v2) => {
            return v.key === v2.balance.split(" ")[1];
          });
          if (t && t.length > 0) {
            v.balance = t[0].balance;
          } else {
            v.balance = "0.0000 " + v.key;
          }
          v.max = parseInt(v.balance);
          return v;
        })
        .filter((v) => {
          return v.balance.split(" ")[1] !== "DCASH";
        });
    },
  },
  methods: {
    moment,
    clickSale() {
      if (this.price <= 0 || this.count <= 0) {
        return;
      }
      let that = this;
      Dialog.confirm({
        title: "提示",
        message: `确定以单价【${parseFloat(this.price).toFixed(4)}】采购${
          this.count
        }个${this.buyCoin.name}？`,
      })
        .then(() => {
          scatter.buy(
            {
              price: (that.count * 10000).toFixed(0),
              quantity: (that.count * that.price).toFixed(4) + " DCASH",
              key: that.buyCoin.balance.split(" ")[1],
            },
            (e) => {
              if (scatter.hcb(e)) {
                that.hidden();
                Toast.success("已提交到市场挂单采购");
                that.$emit("reload");
              }
            }
          );
        })
        .catch(() => {});
    },
    hidden() {
      this.show = false;
      this.$emit("hidden");
    },
  },
  mounted() {
    this.show = this.dialogShow;
    if (this.buy === "" && this.targetCoins.length > 0) {
      this.buy = this.targetCoins[0].balance;
    }
  },
};
</script>

<style scoped lang="scss">
.nft-buy-coin-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
  margin: 10vh auto;
  padding: 10px;
  border-radius: 8px;
  p {
    font-size: 13px;
  }
  .nft-info {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .nft-level {
      margin-left: 8px;
    }
  }
  .nft-img {
    width: 40px;
  }
  .nft-create {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;
    margin-bottom: 4px;
    img {
      width: 20px;
    }
    .nft-create-asset {
      display: flex;
      align-items: center;
    }
    .nft-create-title {
      margin-right: 10px;
      flex-shrink: 0;
    }
    .nft-create-requireasset {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      img {
        width: 20px;
        margin-right: 2px;
      }
      p {
        font-weight: bold;
        font-size: 12px;
        margin-right: 6px;
      }
    }
    button {
      margin-right: 10px;
    }
  }
}
</style>
