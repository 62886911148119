<template>
  <div v-if="nft" class="nft-container">
    <div class="nft-top">
      <img class="nft-img" :src="nft.png" />
      <p class="nft-name">{{ nft.name }}</p>
      <van-tag type="primary" class="nft-level" v-if="nft.level"
        >等级:{{ nft.level }}</van-tag
      >
      <van-tag type="warning" v-if="nft.isFinish" class="nft-task"
        >可收获</van-tag
      >
      <van-tag type="success" v-else-if="nft.task" class="nft-task"
        >生产中</van-tag
      >
      <van-tag type="danger" v-else-if="nft.notask" class="nft-task"
        >待生产</van-tag
      >

      <p
        class="nft-get-time"
        v-if="
          !nft.isFinish &&
            nft &&
            nft.task &&
            subtractTime(nft.task.mature_time) > 0
        "
      >
        <van-icon name="clock-o" />
        {{ getFormatTIme(nft.task.mature_time) }}
      </p>
      <van-button
        v-if="accountName === nft.owner && nft.isFinish"
        style="margin-left: 10px;"
        type="danger"
        size="mini"
        @click="clickOnHarvest(nft.task)"
        >点击收获</van-button
      >
      <van-button
        v-if="accountName === nft.owner && nft.notask"
        style="margin-left: 10px;"
        type="danger"
        size="mini"
        @click="clickOnDeposit"
        >点击生产</van-button
      >
    </div>
    <div class="nft-bottom" v-if="nft.task">
      <p>{{ nft.isFinish ? "可收获" : "待收获" }}</p>
      <img :src="getTaskPng" />
      <p class="nft-get-count">* {{ parseFloat(nft.task.value).toFixed(0) }}</p>
    </div>
    <div class="nft-bottom" v-if="nft.notask">
      <p class="nft-workers-tip">可生产的任务:</p>
      <div class="nft-workers">
        <div class="nft-worker" v-for="worker of nft.workers" :key="worker.id">
          <van-tag
            plain
            class="nft-worker-item"
            type="primary"
            v-for="asset of worker.require_asset"
            :key="`${worker.id}-${asset}`"
            ><p v-if="asset.indexOf(' ') === -1">
              {{ nft.level > 3 ? "n" : nft.level }}USDT
            </p>
            <img
              v-if="asset.indexOf(' ') != -1"
              class="nft-worker-asset"
              :src="getAssetPng(asset)"
            />
            <p v-if="asset.indexOf(' ') != -1" class="nft-get-count">
              {{ (parseFloat(asset) * nft.level).toFixed(0) }}
            </p>
            <img
              class="nft-worker-asset"
              :src="asset"
              v-if="asset.indexOf(' ') == -1"
            />
          </van-tag>
          <van-icon name="/right-arrow.png" class="nft-worker-arrow" />
          <van-tag
            plain
            type="primary"
            v-if="worker.create_asset.indexOf(' ') != -1"
            ><img
              class="nft-worker-asset"
              :src="getAssetPng(worker.create_asset)"
            />
            <p class="nft-get-count">
              {{ (parseFloat(worker.create_asset) * nft.level).toFixed(0) }}
            </p></van-tag
          >
          <van-tag plain type="primary" v-else
            ><img
              class="nft-worker-asset"
              :src="getAssetPng(worker.create_asset)"
            />
            <p class="nft-get-count">
              {{ (parseFloat(worker.create_asset) * nft.level).toFixed(0) }}
            </p></van-tag
          >
          <p class="nft-get-time">
            <van-icon name="clock-o" />
            {{
              // eslint-disable-next-line vue/no-parsing-error
              worker.require_time < 3600
                ? worker.require_time / 60 + "分钟"
                : worker.require_time / 60 / 60 + "小时"
            }}
          </p>
        </div>
      </div>
    </div>
    <dialog-NFT-deposit
      :dialogShow="dialogShow"
      @hidden="dialogShow = fasle"
      :nft="nft"
      :coins="coins"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import scatter from "@/utils/scatter";
import { Toast } from "vant";
import DialogNFTDeposit from "@/components/DialogNFTDeposit";
export default {
  name: "HomeNFT",
  components: {
    DialogNFTDeposit,
  },
  props: {
    nft: null,
    now: null,
    coins: null,
  },
  data() {
    return {
      dialogShow: false,
    };
  },
  watch: {
    now() {},
  },
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
    }),
    getTaskPng() {
      if (this.nft && this.nft.task) {
        for (let crop of this.farmCrops) {
          if (crop.key === this.nft.task.farm) {
            return crop.url;
          }
        }
      }
      return "";
    },
  },
  methods: {
    moment,
    clickOnDeposit() {
      this.dialogShow = true;
      // console.log(nft);
      this.$emit("clickDeposit");
    },
    clickOnHarvest(task) {
      let that = this;
      scatter.harvest(task.id, (e) => {
        if (scatter.hcb(e)) {
          Toast.success("收获成功");
          that.$emit("reload");
        }
      });
    },
    subtractTime(t) {
      return moment(t)
        .add(8, "h")
        .subtract(this.now)
        .unix();
    },
    getFormatTIme(t) {
      var unix = this.subtractTime(t);
      var day = unix / (60 * 60 * 24);
      day = parseInt(day);
      unix = unix - day * (60 * 60 * 24);
      var hours = unix / (60 * 60);
      hours = parseInt(hours);
      unix = unix - hours * (60 * 60);
      var minute = unix / 60;
      minute = parseInt(minute);
      var second = unix - minute * 60;
      return `${day > 0 ? day + "天 " : ""}${
        hours < 10 ? "0" + hours : hours
      }:${minute < 10 ? "0" + minute : minute}:${
        second < 10 ? "0" + second : second
      }`;
    },
    getAssetPng(asset) {
      let a = asset.split(" ")[1];
      for (let crop of this.farmCrops) {
        if (crop.key === a) {
          return crop.url;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.nft-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  .nft-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .nft-name {
      font-size: 12px;
      margin-right: 6px;
    }
    .nft-img {
      width: 30px;
      margin-right: 6px;
    }
    .nft-level {
      margin-right: 6px;
    }
    .nft-level,
    .nft-task {
      font-size: 11px;
    }
    .nft-get-time {
      font-size: 13px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
  .nft-bottom {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    p {
      font-size: 13px;
    }
    .nft-get-count {
      font-weight: bold;
    }
    img {
      margin: 0 4px;
      width: 30px;
    }
    .nft-workers-tip {
      font-size: 12px;
      margin-bottom: 4px;
    }
    .nft-workers {
      width: 100%;
      .nft-worker {
        .nft-worker-arrow {
          margin: 0 4px;
        }
        .nft-worker-item {
          margin-right: 4px;
        }
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        .nft-worker-asset {
          width: 20px;
          padding: 2px 0;
        }
        .nft-get-time {
          font-size: 13px;
          margin-left: 10px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
