import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import dfsnft from "@/utils/dfsnft";
import scatter from "@/utils/scatter";

scatter.store = store;
scatter.toggleRpc();
dfsnft.store = store;

setInterval(() => {
  dfsnft.updateDCASHPrice(store);
}, 60 * 1000);
dfsnft.updateDCASHPrice(store);

createApp(App)
  .use(Vant)
  .use(store)
  .use(router)
  .mount("#app");
