<template>
  <div v-if="luckysResult" class="home-luckys-container">
    <div class="luckys-top" v-for="luckys of luckysResult" :key="luckys.id">
      <div class="luckys-items">
        <img
          class="luckys-img"
          :src="tdfsnft.getAssetPng(null, item)"
          v-for="item of luckys.items"
          :key="luckys.id + item"
        />
      </div>
      <p class="luckys-time">
        时间:
        {{
          moment(luckys.create_time)
            .add(8, "h")
            .format("YYYY-MM-DD HH:mm:ss")
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import dfsnft from "@/utils/dfsnft";
export default {
  name: "HomeLuckys",
  components: {},
  props: {
    luckysResult: null,
  },
  data() {
    return {
      tdfsnft: dfsnft,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
    }),
  },
  methods: {
    moment,
  },
};
</script>

<style scoped lang="scss">
.home-luckys-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  .luckys-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    .luckys-items {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      .luckys-img {
        width: 24px;
        margin-right: 6px;
        margin-bottom: 2px;
      }
    }
    .luckys-time {
      font-size: 11px;
    }
  }
}
</style>
