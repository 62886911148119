<template>
  <van-overlay :show="show">
    <div class="nft-deposit-container" v-if="nft">
      <div class="nft-info">
        <img class="nft-img" :src="nft.png" />
        <van-tag type="primary" class="nft-level" v-if="nft.level"
          >等级:{{ nft.level }}</van-tag
        >
      </div>
      <div class="nft-create" v-if="nft.collection === 'gemfactory'">
        <p class="nft-create-title">
          红宝石、绿宝石、黄玉生产<br />暂未集成，请到游戏官网生产
        </p>
      </div>
      <div class="nft-create">
        <p class="nft-create-title">生产:</p>
        <van-radio-group v-model="createAsset" direction="horizontal">
          <van-radio
            v-for="ca of createAssets"
            :key="ca.key"
            icon-size="14"
            :name="ca.key"
            ><div class="nft-create-asset">
              <p>{{ ca.name }}</p>
              <img :src="tdfsnft.getAssetPng(null, `1.0000 ${ca.key}`)" /></div
          ></van-radio>
        </van-radio-group>
      </div>
      <div class="nft-create">
        <p class="nft-create-title">数量:</p>
        <van-stepper v-model="count" min="1" :max="nft.level" />
      </div>
      <div class="nft-create">
        <p class="nft-create-title">周期:</p>
        <van-stepper v-model="loop" min="1" max="12" />
      </div>
      <div class="nft-create">
        <p class="nft-create-title">时间:&nbsp;&nbsp;{{ workerTime }}</p>
      </div>
      <div class="nft-create">
        <p class="nft-create-title">需要:</p>
        <div
          class="nft-create-requireasset"
          v-for="requireAsset of requireAssets"
          :key="requireAsset.asset"
        >
          <img :src="tdfsnft.getAssetPng(null, requireAsset.asset)" />
          <p>
            {{ requireAsset.value }}
            {{ `(库存:${requireAsset.balance})` }}
          </p>
        </div>
      </div>
      <div class="nft-create">
        <van-button type="primary" size="small" @click="deposit"
          >确定</van-button
        >
        <van-button type="danger" size="small" @click="hidden">取消</van-button>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import scatter from "@/utils/scatter";
import dfsnft from "@/utils/dfsnft";
import { Toast, Dialog } from "vant";

export default {
  emits: ["hidden", "reload"],
  name: "DialogNFTDeposit",
  props: {
    nft: null,
    dialogShow: {
      type: Boolean,
      default: false,
    },
    coins: null,
  },
  data() {
    return {
      show: false,
      createAsset: "",
      count: 1,
      loop: 1,
      tdfsnft: dfsnft,
    };
  },
  watch: {
    dialogShow() {
      this.show = this.dialogShow;
      if (this.show) {
        this.count = this.nft.level;
        this.loop = 1;
      }
    },
  },
  computed: {
    ...mapState({
      accountName: (state) => state.app.accountName,
      farmCrops: (state) => state.app.farmCrops,
      workers: (state) => state.app.workers,
    }),
    workerTime() {
      if (this.createAsset && this.createAsset !== "") {
        for (let w of this.targetWorker) {
          if (w.create_asset.split(" ")[1] === this.createAsset) {
            let t = w.require_time;
            return (t / 60) * this.loop + "分钟";
          }
        }
      }
      return "";
    },
    createWork() {
      if (this.createAsset && this.createAsset !== "") {
        for (let w of this.targetWorker) {
          if (w.create_asset.split(" ")[1] === this.createAsset) {
            return w;
          }
        }
      }
      return null;
    },
    requireAssets() {
      if (this.createWork) {
        return this.createWork.require_asset.flatMap((v) => {
          let c = this.coins
            ? this.coins.filter((v2) => {
                return v2.balance.split(" ")[1] === v.split(" ")[1];
              })
            : null;
          return {
            asset: v,
            value: parseInt(v) * this.count * this.loop,
            balance: c && c[0] ? parseFloat(c[0].balance) : 0,
            p:
              (c && c[0] ? parseFloat(c[0].balance) : 0) <
              parseInt(v) * this.count * this.loop,
          };
        });
      }
      return [];
    },
    targetWorker() {
      if (this.nft) {
        let v = this.workers
          .filter((v) => {
            return v.require_nft_type === this.nft.collection;
          })
          .filter((v) => {
            return v.require_level <= this.nft.level;
          });
        return v;
      }
      return [];
    },
    createAssets() {
      let tw = this.targetWorker.filter((v) => {
        return (
          this.farmCrops
            .flatMap((v) => {
              return v.key;
            })
            .indexOf(v.create_asset.split(" ")[1]) !== -1
        );
      });
      return tw
        .flatMap((v) => {
          return this.farmCrops.filter((v2) => {
            return v2.key === v.create_asset.split(" ")[1];
          })[0];
        })
        .filter((v) => {
          return ["RUBY", "BERYL", "TOPAZ"].indexOf(v.key) === -1;
        });
    },
  },
  methods: {
    moment,
    deposit() {
      if (this.createWork && this.requireAssets.length > 0) {
        for (let a of this.requireAssets) {
          if (a.p) {
            Dialog.alert({
              title: "提示",
              message: "库存不足",
            });
            return;
          }
        }
        let that = this;
        scatter.produce(
          {
            workid: this.createWork.id,
            multiple: `${this.count}`,
            assetid: `${this.nft.id}`,
            round: `${this.loop}`,
            quantity: this.requireAssets.flatMap((v) => {
              return v.value.toFixed(4) + " " + v.asset.split(" ")[1];
            }),
          },
          (e) => {
            if (scatter.hcb(e)) {
              Toast.success("已提交生产");
              that.$emit("reload");
            }
          }
        );
      }
    },
    hidden() {
      this.show = false;
      this.$emit("hidden");
    },
    clickOnCancelOrder(order) {
      let that = this;
      scatter.cancelOrder(order.oid, (e) => {
        if (scatter.hcb(e)) {
          Toast.success("取消成功");
          that.$emit("reload");
        }
      });
    },
  },
  mounted() {
    this.show = this.dialogShow;
    if (this.createAsset === "") {
      if (this.createAssets.length > 0) {
        this.createAsset = this.createAssets[0].key;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.nft-deposit-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
  margin: 30vh auto;
  padding: 10px;
  border-radius: 8px;
  p {
    font-size: 13px;
  }
  .nft-info {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .nft-level {
      margin-left: 8px;
    }
  }
  .nft-img {
    width: 40px;
  }
  .nft-create {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;
    margin-bottom: 4px;
    img {
      width: 20px;
    }
    .nft-create-asset {
      display: flex;
      align-items: center;
    }
    .nft-create-title {
      margin-right: 10px;
      flex-shrink: 0;
    }
    .nft-create-requireasset {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      img {
        width: 20px;
        margin-right: 2px;
      }
      p {
        font-weight: bold;
        font-size: 12px;
        margin-right: 6px;
      }
    }
    button {
      margin-right: 10px;
    }
  }
}
</style>
