import moment from "moment";

class DFSNFT {
  constructor() {
    this.store = null;
  }
  async getAllCoinsPrice() {
    // for (let fc of this.store.getters.app.farmCrops) {
    //   if (fc.key !== "DCASH") {
    //     await this.getSellOrders(fc.key);
    //   }
    // }
  }
  tcoinPriceDetail(order) {
    let require_asset = order.require_asset;
    let workerPrices = this.store.getters.app.workerPrices;
    let ra = require_asset.map((v) => {
      if (workerPrices[v.split(" ")[1]]) {
        return (
          workerPrices[v.split(" ")[1]] * parseFloat(v.split(" ")[0])
        ).toFixed(4);
      }
      return (0).toFixed(4);
    });
    let pa = ra.map((v) => {
      return (
        (v /
          ra.reduce((t, v) => {
            return parseFloat(t) + parseFloat(v);
          }, 0)) *
        (parseFloat(order.pay) + 0)
      ) // <!-- TODO 订单附加许愿池宝石  -->
        .toFixed(4);
    });
    let p = pa.map((v, index) => {
      return (parseFloat(v) - parseFloat(ra[index])).toFixed(4);
    });
    let p2 = p.map((v, index) => {
      return (
        (Object.values(this.store.getters.app.workerPricesDesc).filter((v2) => {
          return v2.k === require_asset[index].split(" ")[1];
        })[0].z *
          parseFloat(v)) /
        parseFloat(require_asset[index].split(" ")[0])
      ).toFixed(4);
    });
    return [
      "单个成本: " +
        ra
          .map((v, index) => {
            return (
              parseFloat(v) / parseFloat(require_asset[index].split(" ")[0])
            ).toFixed(4);
          })
          .join(" + "),
      "单个回收: " +
        pa
          .map((v, index) => {
            return (
              parseFloat(v) / parseFloat(require_asset[index].split(" ")[0])
            ).toFixed(4);
          })
          .join(" + "),
      "单个利润: " +
        p
          .map((v, index) => {
            return (
              parseFloat(v) / parseFloat(require_asset[index].split(" ")[0])
            ).toFixed(4);
          })
          .join(" + "),
      "单日利润: " +
        p2.join(" + ") +
        " = " +
        p2
          .reduce((t, v) => {
            return parseFloat(t) + parseFloat(v);
          }, 0)
          .toFixed(4),
    ];
  }
  tcoinPriceProfit(order) {
    let require_asset = order.require_asset;
    let workerPrices = this.store.getters.app.workerPrices;
    var raPrice = 0;
    for (let ra of require_asset) {
      if (workerPrices[ra.split(" ")[1]]) {
        raPrice +=
          workerPrices[ra.split(" ")[1]] * parseFloat(ra.split(" ")[0]);
      }
    }
    // <!-- TODO 订单附加许愿池宝石  -->
    return parseFloat(order.pay) + 0 - raPrice;
  }
  tcoinPriceInfo(oid) {
    let coinMaxPriceWorker = this.store.getters.app.coinMaxPriceWorker;
    let coinMinPriceWorker = this.store.getters.app.coinMinPriceWorker;
    var result = {
      min: false,
      max: false,
      maxKeys: [],
      minKeys: [],
      minP: 99999,
      maxP: 0,
    };
    for (let key of Object.keys(coinMaxPriceWorker)) {
      if (
        coinMaxPriceWorker[key].id === oid ||
        `${coinMaxPriceWorker[key].id}` === `${oid}`
      ) {
        result.max = true;
        result.maxP = Math.max(coinMaxPriceWorker[key].p, result.maxP);
        result.maxKeys.push(key);
      }
    }
    for (let key of Object.keys(coinMinPriceWorker)) {
      if (
        coinMinPriceWorker[key].id === oid ||
        `${coinMinPriceWorker[key].id}` === `${oid}`
      ) {
        if (result.maxKeys.indexOf(key) === -1) {
          result.min = true;
          result.minP = Math.min(coinMinPriceWorker[key].p, result.minP);
          result.minKeys.push(key);
        }
      }
    }
    return result;
  }
  getCollectionPng(store, collection) {
    return store.getters.app.shopPngPre + collection + ".png";
  }
  getAssetPng(store, asset) {
    let a = asset.split(" ")[1];
    for (let crop of (this.store ? this.store : store).getters.app.farmCrops) {
      if (crop.key === a) {
        return crop.url;
      }
    }
    return "";
  }
  calcNFTTotalPrice({ nfts, sellOrders, buyOrders, coins, store }) {
    var price = 0;
    var nftsTotalPrice = 0;
    var itemsTotalPrice = 0;
    // 计算土地NFT的价值
    for (let nft of nfts) {
      let collection = nft.collection;
      let level = nft.level;
      var nftPrice = 0;
      for (let nftAsset of this.store.getters.app.nftAssets) {
        if (nftAsset.collection === collection) {
          // 一级NFT价格
          nftPrice = 100 + nftAsset.supply / 10;
          break;
        }
      }
      // 升级消耗的DCASH
      let levelUpDcash = 120 * (level - 1);
      let levelUpOther = 0;
      var tOther = 10;
      for (let i = 1; i < level; i++) {
        tOther = 10 + 2 * (i - 1);
        levelUpOther = levelUpOther + tOther;
      }
      nftsTotalPrice = price + nftPrice + levelUpDcash + levelUpOther;
      price = price + nftPrice + levelUpDcash + levelUpOther;
    }
    // 计算物品数量
    var tcoins = {};
    for (let coin of coins) {
      if (coin && coin.balance) {
        tcoins[coin.balance.split(" ")[1]] = parseFloat(
          coin.balance.split(" ")[0]
        );
      }
    }
    // 计算出售订单
    if (sellOrders) {
      for (let sellOrder of sellOrders) {
        if (tcoins[sellOrder.item.split(" ")[1]]) {
          tcoins[sellOrder.item.split(" ")[1]] =
            tcoins[sellOrder.item.split(" ")[1]] +
            parseFloat(sellOrder.item.split(" ")[0]);
        } else {
          tcoins[sellOrder.item.split(" ")[1]] = parseFloat(
            sellOrder.item.split(" ")[0]
          );
        }
      }
    }
    // 就算采购订单
    if (buyOrders) {
      for (let buyOrder of buyOrders) {
        price = price + parseFloat(buyOrder.cash);
        itemsTotalPrice = itemsTotalPrice + parseFloat(buyOrder.cash);
      }
    }
    // 计算物品的价值
    for (let key of Object.keys(tcoins)) {
      if (this.store.getters.app.tcoinPrices[key]) {
        price = price + tcoins[key] * this.store.getters.app.tcoinPrices[key];
        itemsTotalPrice =
          itemsTotalPrice +
          tcoins[key] * this.store.getters.app.tcoinPrices[key];
      }
    }

    // 计算日化 ========= BEGIN ===========
    // 计算日化 ========= BEGIN ===========
    // 计算日化 ========= BEGIN ===========
    var t_nfts = JSON.parse(JSON.stringify(nfts));
    var index = 0;
    for (let nft of t_nfts) {
      let collection = nft.collection;
      let level = nft.level;
      var min_level = 1;
      var max_level = 1;
      for (let worker of this.store.getters.app.workers) {
        if (worker.require_nft_type === collection) {
          if (worker.require_level === min_level) {
            t_nfts[index].min_worker = worker;
          }
          if (
            worker.require_level <= level &&
            worker.require_level >= max_level
          ) {
            t_nfts[index].max_worker = worker;
            max_level = worker.require_level;
          }
        }
      }
      index++;
    }

    var min_asset_24 = {
      require: [],
      create: [],
    };
    var max_asset_24 = {
      require: [],
      create: [],
    };
    index = 0;
    for (let nft of t_nfts) {
      // let collection = nft.collection;
      let level = nft.level;
      let hour_1 = 60 * 60;
      let hour_24 = hour_1 * 24;

      let min_worker = nft.min_worker;
      let min_worker_require_asset = min_worker.require_asset;
      let min_worker_create_asset = min_worker.create_asset;
      let min_worker_require_time = min_worker.require_time;
      let min_time_t = hour_24 / min_worker_require_time;
      for (let a of min_worker_require_asset) {
        if (a.indexOf(" ") !== -1) {
          min_asset_24.require.push(
            `${min_time_t * level * parseFloat(a.split(" ")[0])} ${
              a.split(" ")[1]
            }`
          );
        } else {
          min_asset_24.require.push(
            `${min_time_t *
              level *
              (store.getters.app.dcashPrice === 0
                ? 1
                : 1 / store.getters.app.dcashPrice)} DCASH`
          );
        }
      }
      min_asset_24.create.push(
        `${min_time_t *
          level *
          parseFloat(min_worker_create_asset.split(" ")[0])} ${
          min_worker_create_asset.split(" ")[1]
        }`
      );

      let max_worker = nft.max_worker;
      let max_worker_require_asset = max_worker.require_asset;
      let max_worker_create_asset = max_worker.create_asset;
      let max_worker_require_time = max_worker.require_time;
      let max_time_t = hour_24 / max_worker_require_time;
      for (let a of max_worker_require_asset) {
        if (a.indexOf(" ") !== -1) {
          max_asset_24.require.push(
            `${max_time_t * level * parseFloat(a.split(" ")[0])} ${
              a.split(" ")[1]
            }`
          );
        } else {
          max_asset_24.require.push(
            `${max_time_t *
              level *
              (store.getters.app.dcashPrice === 0
                ? 1
                : 1 / store.getters.app.dcashPrice)} DCASH`
          );
        }
      }
      max_asset_24.create.push(
        `${max_time_t *
          level *
          parseFloat(max_worker_create_asset.split(" ")[0])} ${
          max_worker_create_asset.split(" ")[1]
        }`
      );

      index++;
    }
    // ======== min
    var min_asset_create_dcash = 0;
    var min_asset_require_dcash = 0;
    for (let i = 0; i < min_asset_24.require.length - 1; i++) {
      for (let j = 0; j < min_asset_24.create.length - 1; j++) {
        if (
          parseFloat(min_asset_24.require[i]) > 0 &&
          parseFloat(min_asset_24.create[j]) > 0 &&
          min_asset_24.require[i].split(" ")[1] ===
            min_asset_24.create[j].split(" ")[1]
        ) {
          let v = Math.min(
            parseFloat(min_asset_24.create[j]),
            parseFloat(min_asset_24.require[i])
          );
          min_asset_24.require[i] =
            `${parseFloat(min_asset_24.require[i]) - v}` +
            " " +
            min_asset_24.require[i].split(" ")[1];
          min_asset_24.create[j] =
            `${parseFloat(min_asset_24.create[j]) - v}` +
            " " +
            min_asset_24.create[j].split(" ")[1];
        }
      }
    }
    for (let c of min_asset_24.create) {
      var o = this.store.getters.app.info.filter((v) => {
        return v.key === c.split(" ")[1];
      })[0];
      min_asset_create_dcash +=
        (c.split(" ")[1] === "DCASH" ? 1 : o.price) *
        parseFloat(c.split(" ")[0]);
    }
    for (let r of min_asset_24.require) {
      min_asset_require_dcash +=
        this.store.getters.app.workerPrices[r.split(" ")[1]] *
        parseFloat(r.split(" ")[0]);
    }
    var min_asset_24_profit = min_asset_create_dcash - min_asset_require_dcash;
    // ======== max
    var max_asset_create_dcash = 0;
    var max_asset_require_dcash = 0;
    for (let i = 0; i < max_asset_24.require.length - 1; i++) {
      for (let j = 0; j < max_asset_24.create.length - 1; j++) {
        if (
          parseFloat(max_asset_24.require[i]) > 0 &&
          parseFloat(max_asset_24.create[j]) > 0 &&
          max_asset_24.require[i].split(" ")[1] ===
            max_asset_24.create[j].split(" ")[1]
        ) {
          let v = Math.min(
            parseFloat(max_asset_24.create[j]),
            parseFloat(max_asset_24.require[i])
          );
          max_asset_24.require[i] =
            `${parseFloat(max_asset_24.require[i]) - v}` +
            " " +
            max_asset_24.require[i].split(" ")[1];
          max_asset_24.create[j] =
            `${parseFloat(max_asset_24.create[j]) - v}` +
            " " +
            max_asset_24.create[j].split(" ")[1];
        }
      }
    }
    for (let c of max_asset_24.create) {
      o = this.store.getters.app.info.filter((v) => {
        return v.key === c.split(" ")[1];
      })[0];
      if (o) {
        max_asset_create_dcash +=
          (c.split(" ")[1] === "DCASH" ? 1 : o.price) *
          parseFloat(c.split(" ")[0]);
      }
    }
    for (let r of max_asset_24.require) {
      max_asset_require_dcash +=
        this.store.getters.app.workerPrices[r.split(" ")[1]] *
        parseFloat(r.split(" ")[0]);
    }
    var max_asset_24_profit = max_asset_create_dcash - max_asset_require_dcash;
    // 计算日化 ========= END ===========
    // 计算日化 ========= END ===========
    // 计算日化 ========= END ===========
    let taskItemsTotalPrice = 0;
    for (let nft of nfts) {
      if (nft.task) {
        if (
          this.store.getters.app.tcoinPrices[nft.task.product.split(" ")[1]]
        ) {
          taskItemsTotalPrice =
            taskItemsTotalPrice +
            parseFloat(nft.task.product) *
              this.store.getters.app.tcoinPrices[
                nft.task.product.split(" ")[1]
              ];
        }
      }
    }
    price = price + taskItemsTotalPrice;
    return {
      price,
      itemsTotalPrice,
      taskItemsTotalPrice,
      nftsTotalPrice,
      max_asset_24_profit,
      min_asset_24_profit,
    };
  }
  async updateDCASHPrice(store) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "defisswapcnt",
      scope: "defisswapcnt",
      table: "markets",
      json: true,
      limit: 1,
      lower_bound: 1697,
    });
    if (res && res.rows && res.rows[0]) {
      let row = res.rows[0];
      let r0 = row.reserve0;
      let r1 = row.reserve1;
      let p = parseFloat(r0) / parseFloat(r1);
      store.dispatch("setDcashPrice", p);
    }
  }

  async setWorkPrices() {
    if (this.store) {
      let workerPrices = this.store.getters.app.workerPrices;
      for (let worker of this.store.getters.app.workers) {
        if (!workerPrices[worker.create_asset.split(" ")[1]]) {
          workerPrices[worker.create_asset.split(" ")[1]] = 0;
        }
        for (let asset of worker.require_asset) {
          if (asset.indexOf(" ") != -1) {
            workerPrices[worker.create_asset.split(" ")[1]] +=
              workerPrices[asset.split(" ")[1]] *
              parseFloat(asset.split(" ")[0]);
          } else {
            workerPrices[worker.create_asset.split(" ")[1]] =
              this.store.getters.app.dcashPrice === 0
                ? 1
                : 1 / this.store.getters.app.dcashPrice; // 1usdt
          }
        }
        workerPrices[worker.create_asset.split(" ")[1]] =
          workerPrices[worker.create_asset.split(" ")[1]] /
          parseFloat(worker.create_asset.split(" ")[0]);
        this.store.dispatch("setWorkerPrices", workerPrices);
      }
    }
  }
  async getTradeLogs(account) {
    var result = [];
    var res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsftsmarket",
      scope: "dfsftsmarket",
      table: "tradelogs",
      json: true,
      limit: 10,
      upper_bound: " " + account,
      reverse: true,
      index_position: 2,
      key_type: "i64",
      lower_bound: " " + account,
    });
    result = result.concat(res && res.rows ? res.rows : []);
    res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsftsmarket",
      scope: "dfsftsmarket",
      table: "tradelogs",
      json: true,
      limit: 10,
      upper_bound: " " + account,
      reverse: true,
      index_position: 3,
      key_type: "i64",
      lower_bound: " " + account,
    });
    result = result.concat(res && res.rows ? res.rows : []);
    result = result
      .sort((a, b) => {
        return moment(b.time)
          .subtract(moment(a.time))
          .unix();
      })
      .slice(0, 10);
    return result;
  }
  async getCoins(account) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsfarmitems",
      scope: " " + account,
      table: "accounts",
      json: true,
      limit: 1000,
    });
    return res;
  }
  async getAccountSellOrders(account) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsftsmarket",
      scope: "dfsftsmarket",
      table: "sellorders",
      json: true,
      limit: 200,
      reverse: true,
      index_position: 2,
      key_type: "i64",
      lower_bound: " " + account,
      upper_bound: " " + account,
    });
    return res;
  }
  async getSellOrders(asset) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsftsmarket",
      scope: "dfsftsmarket",
      table: "sellorders",
      json: true,
      limit: 500,
      reverse: true,
      index_position: 3,
      key_type: "i64",
      upper_bound: asset,
      lower_bound: asset,
    });
    if (res && res.rows && res.rows[0]) {
      this.store.dispatch("setSellOrders", {
        k: asset,
        sellOrders: res.rows.sort((a, b) => {
          return (
            parseFloat(a.cash) / parseFloat(a.item) -
            parseFloat(b.cash) / parseFloat(b.item)
          );
        })[0],
      });
    }
    return res;
  }
  async getLuckysResult(account) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsmetaverse",
      scope: " " + account,
      table: "luckysresult",
      json: true,
      limit: 10,
      reverse: true,
    });
    return res;
  }
  async getBuyOrders(asset) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsftsmarket",
      scope: "dfsftsmarket",
      table: "buyorders",
      json: true,
      limit: 500,
      reverse: true,
      index_position: 3,
      key_type: "i64",
      upper_bound: asset,
      lower_bound: asset,
    });
    return res;
  }
  async getAccountBuyOrders(account) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsftsmarket",
      scope: "dfsftsmarket",
      table: "buyorders",
      json: true,
      limit: 200,
      reverse: true,
      index_position: 2,
      key_type: "i64",
      lower_bound: " " + account,
      upper_bound: " " + account,
    });
    return res;
  }
  async getNftAssets() {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsnftassets",
      scope: "dfsnftassets",
      table: "stat",
      json: true,
      limit: 100,
    });
    if (res && res.rows && this.store) {
      this.store.dispatch("setNftAssets", res.rows);
    }
    return res;
  }
  async getNFT(account) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsnftassets",
      scope: "dfsnftassets",
      table: "registry",
      json: true,
      limit: 1000,
      lower_bound: " " + account,
      upper_bound: " " + account,
      index_position: 3,
      key_type: "i64",
    });
    return res;
  }
  async getOrders() {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsmetaverse",
      scope: "dfsmetaverse",
      table: "orders",
      json: true,
      limit: 100,
    });
    if (res && res.rows && this.store) {
      this.store.dispatch("setOrders", res.rows);
    }
    await this.getAllCoinsPrice();
    return res;
  }
  async getOrderLogs(account) {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsmetaverse",
      scope: account,
      table: "orderlogs",
      json: true,
      limit: 100,
    });
    return res;
  }
  async getWorker() {
    const res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsmetaverse",
      scope: "dfsmetaverse",
      table: "works",
      json: !0,
      limit: 100,
    });
    if (res && res.rows && this.store) {
      this.store.dispatch("setWorkers", res.rows);
    }
    return res;
  }
  async getStats(id) {
    let res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsmetaverse",
      scope: id,
      table: "stats",
      json: true,
      limit: 20,
    });
    return res;
  }
  async getTasks(id) {
    let res = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsmetaverse",
      scope: "dfsmetaverse",
      table: "tasks",
      json: true,
      limit: 20,
      lower_bound: id,
      upper_bound: id,
    });
    return res;
  }
  async getProfit(account) {
    const profit = await this.store.getters.app.rpc.get_table_rows({
      code: "dfsmetaverse",
      scope: "dfsmetaverse",
      table: "profiles",
      json: true,
      lower_bound: " " + account,
      upper_bound: " " + account,
      limit: 100,
    });
    return profit;
  }
}
const dfsnft = new DFSNFT();
export default dfsnft;
